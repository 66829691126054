import { Currencies } from './generated/graphql';

export const CLIENT_VERSION_HEADER = 'X-Client-Version';

export const ROWS_PER_PAGE_MONITORING = 'rowsPerPageMonitoring';
export const ROWS_PER_PAGE_VALIDATION = 'rowsPerPageValidation';

export enum ActivitySectionEnum {
  'HR' = 'Vie du cabinet',
  'VCT' = 'Toutes Absences',
  'INT' = 'Missions Internes',
  'EXT' = 'Missions Externes',
  'PRD' = 'Développement Produit',
  'CFT' = "Appels d'Offres",
}

export enum SharedActivityNameEnum {
  'RECUP' = 'Jours de récupération',
  'FERIE' = 'Jours fériés',
  'RH' = 'RH',
  'RECRUTEMENT' = 'Recrutement',
  'COMAN' = 'COMAN',
  'COMEX' = 'COMEX',
  'FOR' = 'Formation',
}

export enum PongoCollaboratorActivity {
  'PONGO' = 'QWN0aXZpdHlOb2RlOjUw',
}

export enum CategoryOrder {
  'EXT',
  'INT',
  'CFT',
  'PRD',
  'HR',
  'VCT',
}

export enum LanguageRegionEnum {
  fr_FR = 'fr_FR',
  en_GB = 'en_GB',
}

export enum EmployeeForTestEnum {
  'CONTROL' = 'control0',
  'EMPLOYEE' = 'employee0',
}

// do we keep this behavior to have sub categories? might be usefull if we want to regroup plato/blue for example
export const SubActivityName = ['dummy'];

export const DisabledActivityInputName = [
  'jours de récupération',
  'absences',
  'jours fériés',
];

export enum VctActivityOrder {
  'Jours fériés' = 3,
  'Absences' = 1,
  'Jours de récupération' = 2,
}

export const RecoveryDayMoment = {
  allDay: 'AD',
  morning: 'MO',
  afternoon: 'AF',
};

export const NUMBER_DISPLAYED_WEEKS = 7;

export const ACTIVITY_DATE_FORMAT = 'MMMM yyyy';

export const POLY_DATE = 'DD/MM/YYYY';

export const POLY_DATE_MONTH = 'MM/YYYY';

export const POLY_DATE_MONTH_TIME = 'DD MMMM YYYY, HH:mm';

export const WEEK_DATE = 'D';

export const POLY_MONTH_CALENDAR = {
  months: [
    'Janvier',
    'Fevrier',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
  ],
  from: 'De',
  to: 'à',
};
export const PageTitles = {
  activityPage: 'Déclaration',
  absencePage: 'Mes absences',
  polytrombiPage: 'Collaborateurs',
  applicationsPage: 'Applications',
  dashboardPage: 'Dashboard',
  activityMonitoring: 'Suivi temps équipe',
  validateActivityMonitoringPage: 'Validation suivi régie',
  exceptionalActivities: 'Activités exceptionnelles',
  estimatedRevenuePage: 'Chiffre d’affaires prévisionnel',
  billedRevenuePage: 'Chiffre d’affaires facturé',
  actualRevenuePage: 'Chiffre d’affaires réalisé',
  purchaseOrdersPage: 'Bons de commande',
  billsPage: 'Suivi factures',
  paymentsPage: 'Suivi paiements',
  billingClientsPage: 'Gestion clients',
  billingSectorsPage: 'Gestion secteurs',
  profitabilityPage: 'Rentabilité mission',
  missionsSummaryPage: 'Résumé des missions',
  keyIndicatorsPage: 'Indicateurs clés',
  adminEmployeesPage: 'Collaborateurs',
  adminActivitiesPage: 'Missions',
};

export const ExceptionalActivitiesTitles = {
  myAuthorization: 'Mes autorisations',
  declaration: 'Mes activités',
  recuperation: 'Mes récupérations',
  authorization: 'Nouvelle autorisation',
  authorizationGranted: 'Autorisations accordées',
  declarationManager: 'Demandes équipe',
};

export const SaveButtonName = 'ENREGISTRER';

export const InitializeButtonName = 'INITIALISER';

export const NextButtonName = 'SUIVANT';

export const PreviousButtonName = 'PRÉCÉDENT';

export const UploadButtonName = 'TÉLÉVERSER';

export const FRENCH_VAT_RATE = 0.2;

export const CurrenciesList = [
  {
    value: 'EUR',
    label: 'Euro',
  },
  {
    value: 'USD',
    label: 'Dollar américain',
  },
  {
    value: 'NZD',
    label: 'Dollar néo-zélandais',
  },
  {
    value: 'GBP',
    label: 'Livre sterling',
  },
  {
    value: 'CFP',
    label: 'Franc Pacifique',
  },
  {
    value: 'CFA',
    label: 'Franc CFA',
  },
];

export const CurrenciesSymbols: { [key in Currencies]: string } = {
  EUR: '€',
  USD: '$',
  NZD: '$',
  GBP: '£',
  CFP: 'CFP',
  CFA: 'CFA',
};

export const DEFAULT_PROFILE_PICTURE_PATH = '/img/faviconPongo.svg';

export const AVATAR_PONGO_PATH = '/img/avatarPongo.svg';

export const AVATAR_BLUE_PONGO_PATH = '/img/bluePongoAvatar.svg';

export const eventCalendarUrl =
  'https://calendar.google.com/calendar/u/0?cid=Y18zbzYwZWp0dWM2NHE3MmFsazllMmt2NGhub0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t';

export const timeForDrawerUnfilterDebounce = 750;

export const ehpUrl = 'pongo.polyconseil.ehp.red';
export const prodUrl = 'pongo.polyconseil.fr';
export const devUrl = 'localhost';

export const IMAGE_EXTENSIONS = '.jpg,.jpeg,.png';

export const EMPLOYEE_HISTORIZATION_START = '2023-05-05';

export const WHIT_MONDAY_NAME = 'Lundi de Pentecôte';

export const EMPTY_FIELD = '-';

export const DATA_LOADING_ERROR_MESSAGE =
  'Erreur lors du chargement des données';

export const SECTION_BODY_HEIGHT = 600;

export const COMPANY_NAME = 'Polyconseil';
