import { Box } from '@mui/material';
import EmployeeTable from 'components/AdminPage/AdminEmployee/EmployeeTable';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import PageWrapper from 'components/commons/PageWrapper';
import PolyHeader from 'components/commons/PolyHeader';
import {
  EmployeeNode,
  useAllEmployeesForBackOfficeQuery,
} from 'generated/graphql';
import { enqueueSnackbar } from 'notistack';
import { DATA_LOADING_ERROR_MESSAGE, PageTitles } from 'poly-constants';
import React from 'react';

const AdminEmployeesPage = () => {
  const { data, loading } = useAllEmployeesForBackOfficeQuery({
    onError: () => {
      enqueueSnackbar(DATA_LOADING_ERROR_MESSAGE, {
        variant: 'error',
      });
    },
  });
  const employees = data?.allEmployeesForBackOffice || [];
  return (
    <PageWrapper noLimit>
      <PolyHeader>{PageTitles.adminEmployeesPage}</PolyHeader>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            height: '600px',
            alignItems: 'center',
          }}
        >
          <LoadingPlaceholder />
        </Box>
      ) : (
        <EmployeeTable employees={employees as EmployeeNode[]} />
      )}
    </PageWrapper>
  );
};

export default AdminEmployeesPage;
