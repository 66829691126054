import { TableCell, ToggleButton, Tooltip, Typography } from '@mui/material';
import {
  BillingCellType,
  useTableContext,
} from 'contexts/ValidateActivityMonitoring/TableContextProvider';
import {
  ActivityNode,
  BillingBillingStatusChoices,
  BillingNode,
  EmployeeNode,
} from 'generated/graphql';
import { Moment } from 'moment';
import { EditBillingTimeModal } from 'pages/ActivityPage/ValidateActivityMonitoringPage/EditBillingTimeModal';
import { getBillingKey } from 'pages/ActivityPage/ValidateActivityMonitoringPage/ValidateTable';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { dataSkeleton } from './EmployeeRow';

interface EmployeeRowProps {
  employee: EmployeeNode;
  mission: ActivityNode;
  isValidationNeeded: Boolean;
  month: Moment;
  collaboratorDaysDeclared?: number;
}

function getBillingDifference(billing?: BillingNode, declaredDays?: number) {
  if (billing === undefined || declaredDays === undefined) {
    return 0;
  }
  const billDays = billing.timeForTmContracts || 0;
  return billDays - declaredDays;
}

export const BillingCell = ({
  employee,
  mission,
  isValidationNeeded,
  month,
  collaboratorDaysDeclared,
}: EmployeeRowProps) => {
  const {
    billings,
    mode,
    billingCells,
    setSelectedBillingValidations,
    selectedBillingValidations,
    isComputing,
  } = useTableContext();
  const [open, setOpen] = useState(false);

  const key = useMemo(() => {
    return getBillingKey(employee, mission, month);
  }, [employee, mission, month]);

  const billing = billings[key];
  const billingCell: BillingCellType = useMemo(() => {
    const billingCell = billingCells[key];
    if (!billingCell) {
      const newBillingCell = {
        billing,
        employee,
        mission,
        month,
      };
      billingCells[key] = newBillingCell;
      return newBillingCell;
    }
    return billingCell;
  }, [billing, billingCells, employee, key, mission, month]);

  const selected = useMemo(() => {
    return selectedBillingValidations.has(billingCell);
  }, [billingCell, selectedBillingValidations]);

  const setSelected = useCallback(
    (p: boolean) => {
      if (p) {
        selectedBillingValidations.add(billingCell);
      } else {
        selectedBillingValidations.delete(billingCell);
      }
      setSelectedBillingValidations(
        new Set<BillingCellType>(selectedBillingValidations)
      );
    },
    [billingCell, selectedBillingValidations, setSelectedBillingValidations]
  );

  const isValidated =
    billing && billing.status !== BillingBillingStatusChoices.Pending;

  const isValidatedByManagerOrCdg = isValidated || mode;

  const isToggleable =
    (mode === 'validation' && isValidationNeeded && !isValidated) ||
    (mode === 'edit' && billing?.status !== BillingBillingStatusChoices.Billed);

  const billingDiff = getBillingDifference(billing, collaboratorDaysDeclared);

  const tooltipContent =
    billingDiff !== 0 ? (
      <Typography fontSize="12px" color="white">
        Attention, risque de {billingDiff > 0 ? 'surfacturer' : 'sous-facturer'}{' '}
        !
        <br />
        Réel déclaré :{' '}
        <Typography
          fontSize="12px"
          color="white"
          fontWeight={'bold'}
          sx={{ display: 'inline' }}
        >
          {collaboratorDaysDeclared}
        </Typography>
      </Typography>
    ) : (
      ''
    );

  const [daysToConsider, setDaysToConsider] = useState(
    (billing?.timeForTmContracts ?? collaboratorDaysDeclared) || 0
  );

  useEffect(() => {
    setDaysToConsider(
      (billing?.timeForTmContracts ?? collaboratorDaysDeclared) || 0
    );
  }, [billing, collaboratorDaysDeclared]);

  const billingContent = billingDiff ? (
    <Tooltip
      title={
        !isValidationNeeded || isValidatedByManagerOrCdg ? tooltipContent : ''
      }
      arrow
    >
      <Typography color={'orange.main'}>{daysToConsider}</Typography>
    </Tooltip>
  ) : (
    <Typography>{daysToConsider}</Typography>
  );

  return (
    <Tooltip
      title={
        isValidationNeeded && !isValidatedByManagerOrCdg ? (
          <Typography fontSize="12px" color="white">
            Ce temps régie n&apos;a pas été validé par un manager ou CDG
            <br />
            {tooltipContent}
          </Typography>
        ) : (
          ''
        )
      }
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: 'None',
          },
        },
      }}
    >
      <TableCell
        sx={{
          padding: '5px',
          border: 0,
          boxShadow: (theme) =>
            isValidationNeeded && !isValidated && !selected
              ? `inset 2px 2px ${theme.palette.error.main}, inset -2px -2px ${theme.palette.error.main}`
              : '',
        }}
        align={'center'}
      >
        {isToggleable ? (
          <ToggleButton
            value="check"
            size={'small'}
            selected={selected}
            onChange={() => {
              if (mode === 'validation') {
                setSelected(!selected);
              } else if (mode === 'edit') {
                setOpen(true);
              }
            }}
            fullWidth
            sx={{
              boxShadow: 1,
              '&.Mui-selected': {
                backgroundColor: 'success.light',
                '& .MuiTypography-root': {
                  color: 'black',
                },
                '&: hover': {
                  backgroundColor: 'success.main',
                  '& .MuiTypography-root': {
                    color: 'white',
                  },
                },
              },
            }}
          >
            {billingContent}
          </ToggleButton>
        ) : (
          <React.Fragment>
            {isComputing.billing ? dataSkeleton : billingContent}
          </React.Fragment>
        )}
        <EditBillingTimeModal
          open={open}
          setOpen={setOpen}
          billingCell={billingCell}
          daysToConsider={daysToConsider}
          setDaysToConsider={setDaysToConsider}
        />
      </TableCell>
    </Tooltip>
  );
};
