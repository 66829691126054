import { Dialog, DialogActions, DialogProps } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import React from 'react';

import PongoButton from '../PongoButton';
import PolyModalHeader from './DefaultComponents/PolyModalHeader';

interface PolyModalProps {
  open: boolean;
  onClose: () => void;
  polyDialogTitle: string | React.ReactElement;
  polyDialogIcon?: React.ReactElement;
  handlePrimary?: () => void;
  handleSecondary?: () => void;
  primaryButtonName?: string;
  secondaryButtonName?: string;
  isPrimaryButtonDisabled?: boolean;
  noPrimaryButton?: boolean;
  noSecondaryButton?: boolean;
  extraFirstButton?: JSX.Element;
  childrenInForm?: boolean;
  children?: JSX.Element;
  isLoading?: boolean;
  loadingButton?: boolean;
}

export default function PolyModal({
  open,
  onClose,
  polyDialogTitle,
  polyDialogIcon,
  handlePrimary,
  handleSecondary,
  primaryButtonName,
  secondaryButtonName,
  isPrimaryButtonDisabled = false,
  noSecondaryButton = false,
  noPrimaryButton = false,
  extraFirstButton,
  childrenInForm,
  children,
  isLoading = false,
  loadingButton = false,
  ...dialogProps
}: PolyModalProps & DialogProps) {
  const childrenAndModalButtons = (
    <>
      {children}
      {!noPrimaryButton && !noSecondaryButton && (
        <DialogActions sx={{ paddingTop: 2 }}>
          {extraFirstButton}
          {noSecondaryButton === false && (
            <PongoButton
              onClick={handleSecondary ? handleSecondary : onClose}
              variant="contained"
              buttonStyle="secondary"
            >
              {secondaryButtonName ? secondaryButtonName : 'Annuler'}
            </PongoButton>
          )}
          {!noPrimaryButton && handlePrimary && (
            <PongoButton
              type={childrenInForm ? 'submit' : undefined}
              variant={'contained'}
              color={'primary'}
              onClick={handlePrimary}
              disabled={isPrimaryButtonDisabled}
              loading={loadingButton}
            >
              {primaryButtonName ? primaryButtonName : 'Enregistrer'}
            </PongoButton>
          )}
        </DialogActions>
      )}
    </>
  );

  return (
    <Dialog open={open} onClose={onClose} {...dialogProps}>
      {isLoading && (
        <DialogContent>
          <LoadingPlaceholder />
        </DialogContent>
      )}
      <DialogContent hidden={isLoading}>
        <PolyModalHeader
          handleClose={onClose}
          polyDialogTitle={polyDialogTitle}
          polyDialogIcon={polyDialogIcon}
          sx={{ paddingBottom: 2 }}
        />
        {childrenInForm ? (
          <form>{childrenAndModalButtons}</form>
        ) : (
          childrenAndModalButtons
        )}
      </DialogContent>
    </Dialog>
  );
}
