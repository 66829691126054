import { Person } from '@mui/icons-material';
import { Autocomplete, Box, Grid, InputAdornment } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import InfoTooltip from 'components/commons/InfoTooltip';
import SectionTitle from 'components/commons/SectionTitle';
import {
  AddressType,
  parseAddress,
} from 'components/MissionFollowUp/DisplayAddress';
import GridSubTitle from 'components/MissionFollowUp/GridSubTitle';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import {
  BillingClientNode,
  BusinessClientNode,
  EmployeeNode,
} from 'generated/graphql';
import {
  isArrayFilled,
  requiredForm,
} from 'pages/MissionFollowUp/formValidators';
import React from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { displayEmployee } from 'utils';

interface ExternalActivityInfoFormProps {
  employees: EmployeeNode[];
  allComexEmployees: EmployeeNode[];
  billingClients: BillingClientNode[];
  businessClients: BusinessClientNode[];
  setAddress: React.Dispatch<React.SetStateAction<AddressType>>;
}

export default function ExternalActivityInfoForm({
  employees,
  allComexEmployees,
  billingClients,
  businessClients,
  setAddress,
}: ExternalActivityInfoFormProps): JSX.Element {
  const { errors, control, trigger } = useFormContext();

  return (
    <Box sx={{ display: 'inherit' }}>
      <Grid container spacing={0} sx={{ paddingBottom: 2 }}>
        <GridSubTitle customspacingtop={20}>
          <SectionTitle variant="h3Bold">
            Informations de la mission
          </SectionTitle>
        </GridSubTitle>

        <Grid item xs={12}>
          <Controller
            name="name"
            rules={{ required: requiredForm }}
            render={(properties) => (
              <LabelTextFieldGrid
                {...properties}
                onChange={(e) => {
                  properties.onChange(e);
                  trigger('name');
                }}
                required
                errorform={errors.name as FieldError}
                name={'name'}
                title="Nom de la mission"
                sizegrid={12}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name={'selectedBillingClient'}
            rules={{ required: requiredForm }}
            control={control}
            render={(properties) => (
              <Autocomplete
                id={'selected-billing-client'}
                autoComplete
                autoHighlight
                {...properties}
                options={billingClients}
                isOptionEqualToValue={(option, value) =>
                  option ? value.id === option.id : true
                }
                onChange={(_, data) => {
                  properties.onChange(data);
                  if (data) {
                    setAddress(parseAddress(data));
                  }
                  trigger('selectedBillingClient');
                }}
                getOptionLabel={(client) => client.corporateName}
                renderInput={(parameters) => (
                  <LabelTextFieldGrid
                    required
                    title="Client à facturer"
                    sizegrid={12}
                    customElementInTitle={
                      <InfoTooltip
                        style={{ pointerEvents: 'auto' }}
                        title={
                          'La liste des clients est celle de Talentia. Si votre client ne figure pas dans la liste, veuillez contacter le service comptable'
                        }
                      />
                    }
                    {...parameters}
                    errorform={errors.selectedBillingClient as FieldError}
                    InputProps={{
                      ...parameters.InputProps,
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name={'selectedBusinessClient'}
            rules={{ required: requiredForm }}
            defaultValue={undefined}
            control={control}
            render={(properties) => (
              <Autocomplete
                id={'selected-business-client'}
                autoComplete
                autoHighlight
                {...properties}
                options={businessClients}
                isOptionEqualToValue={(option, value) =>
                  option ? value.id === option.id : true
                }
                onChange={(_, data) => {
                  properties.onChange(data);
                  trigger('selectedBusinessClient');
                }}
                getOptionLabel={(client) => client.name}
                renderInput={(parameters) => (
                  <LabelTextFieldGrid
                    required
                    title="Client métier"
                    sizegrid={12}
                    {...parameters}
                    errorform={errors.selectedBusinessClient as FieldError}
                    InputProps={{
                      ...parameters.InputProps,
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="refMarket"
            render={(properties) => (
              <LabelTextFieldGrid
                {...properties}
                onChange={(e) => {
                  properties.onChange(e);
                  trigger('refMarket');
                }}
                errorform={errors.refMarket as FieldError}
                name={'refMarket'}
                title="Identifiant du marché"
                customElementInTitle={
                  <InfoTooltip
                    style={{ pointerEvents: 'auto' }}
                    title={
                      'Identifiant du marché qui figurera sur la facture pour que le client reconnaisse le contrat facturé.'
                    }
                  />
                }
                sizegrid={12}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          {allComexEmployees && (
            <Controller
              name={'selectedDirector'}
              rules={{ required: requiredForm }}
              control={control}
              render={(properties) => (
                <Autocomplete
                  {...properties}
                  autoComplete
                  id={'selected-director'}
                  defaultValue={undefined}
                  autoHighlight
                  options={allComexEmployees}
                  isOptionEqualToValue={(option, value) =>
                    option ? value.id === option.id : true
                  }
                  onChange={(_, data) => {
                    properties.onChange(data);
                    trigger('selectedDirector');
                  }}
                  getOptionLabel={(option) =>
                    displayEmployee(option.firstName, option.lastName)
                  }
                  renderInput={(parameters) => (
                    <LabelTextFieldGrid
                      required
                      name={'selected-director'}
                      title="Directeur de mission"
                      sizegrid={12}
                      {...parameters}
                      errorform={errors.selectedDirector as FieldError}
                      InputProps={{
                        ...parameters.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Person />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />
          )}
        </Grid>

        <Grid item xs={6}>
          {employees && (
            <Controller
              name={'selectedChiefs'}
              rules={{
                required: requiredForm,
                validate: {
                  isArrayFilled,
                },
              }}
              control={control}
              render={(properties) => (
                <Autocomplete
                  {...properties}
                  autoComplete
                  multiple
                  id={'selected-chiefs'}
                  defaultValue={undefined}
                  autoHighlight
                  options={employees}
                  size={'small'}
                  isOptionEqualToValue={(option, value) =>
                    option ? value.id === option.id : true
                  }
                  onChange={(_, data) => {
                    properties.onChange(data);
                    trigger('selectedChiefs');
                  }}
                  getOptionLabel={(option) =>
                    displayEmployee(option.firstName, option.lastName)
                  }
                  renderInput={(parameters) => (
                    <LabelTextFieldGrid
                      required
                      name={'selected-chiefs'}
                      title="Chef(s) de la mission"
                      sizegrid={12}
                      {...parameters}
                      errorform={errors.selectedChiefs as FieldError}
                      InputProps={{
                        ...parameters.InputProps,
                      }}
                    />
                  )}
                />
              )}
            />
          )}
        </Grid>

        <Grid item xs={6}>
          <Controller
            name={'selectedLeadDevs'}
            render={(properties) => (
              <Autocomplete
                autoComplete
                multiple
                id={'selected-lead-devs'}
                {...properties}
                size={'small'}
                options={employees}
                isOptionEqualToValue={(option, value) =>
                  option ? value.id === option.id : true
                }
                onChange={(_, data) => properties.onChange(data)}
                getOptionLabel={(option) =>
                  displayEmployee(option.firstName, option.lastName)
                }
                renderInput={(parameters) => (
                  <LabelTextFieldGrid
                    name={'selected-lead-dev(s)'}
                    title="Lead dev(s)"
                    sizegrid={12}
                    {...parameters}
                    errorform={errors.selectedLeadDevs as unknown as FieldError}
                    InputProps={{
                      ...parameters.InputProps,
                    }}
                  />
                )}
              />
            )}
            control={control}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="startDate"
            rules={{ required: requiredForm }}
            render={(properties) => (
              <DatePicker
                {...properties}
                onChange={(e) => {
                  properties.onChange(e);
                  trigger('startDate');
                }}
                renderInput={(props) => (
                  <LabelTextFieldGrid
                    {...props}
                    required
                    title="Date de début"
                    name={'startDate'}
                    errorform={errors.startDate as FieldError}
                    sizegrid={12}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="expirationDate"
            rules={{ required: requiredForm }}
            render={(properties) => (
              <DatePicker
                {...properties}
                onChange={(e) => {
                  properties.onChange(e);
                  trigger('expirationDate');
                }}
                renderInput={(props) => (
                  <LabelTextFieldGrid
                    {...props}
                    required
                    title="Date de fin"
                    name={'expirationDate'}
                    errorform={errors.expirationDate as FieldError}
                    sizegrid={12}
                  />
                )}
              />
            )}
          />
        </Grid>

        <Controller
          name="description"
          render={(properties) => (
            <LabelTextFieldGrid
              {...properties}
              onChange={(e) => {
                properties.onChange(e);
              }}
              errorform={errors.description as FieldError}
              name={'description'}
              title="Description de la mission"
              type="description"
              sizegrid={12}
              multiline
              rows={3}
            />
          )}
        />
        <Controller
          name="techStack"
          render={(properties) => (
            <LabelTextFieldGrid
              {...properties}
              onChange={(e) => {
                properties.onChange(e);
              }}
              errorform={errors.techStack as FieldError}
              name={'techStack'}
              title="Stack technique"
              type="techStack"
              sizegrid={12}
              multiline
              rows={3}
            />
          )}
        />
      </Grid>
    </Box>
  );
}
