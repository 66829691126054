import { Search } from '@mui/icons-material';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { useUserInfo } from 'components/User/UserProvider';
import { useTableContext } from 'contexts/ValidateActivityMonitoring/TableContextProvider';
import { EmployeeNode } from 'generated/graphql';
import React from 'react';
import { displayEmployee } from 'utils';

export default function CollabFilterAm() {
  const { allCollaborators, setFilterCollabIds } = useTableContext();
  const userInfo = useUserInfo();

  if (allCollaborators.length === 0) {
    return <React.Fragment />;
  }

  return (
    <Autocomplete
      autoHighlight
      autoComplete
      id="filter-select-collab"
      options={allCollaborators}
      onChange={(event, value) => {
        const collaborator = value as EmployeeNode;
        if (collaborator) {
          setFilterCollabIds([collaborator.id]);
        } else {
          setFilterCollabIds([]);
        }
      }}
      getOptionLabel={(option) => {
        if (option) {
          return displayEmployee(option.firstName, option.lastName);
        }
        return '';
      }}
      sx={{ width: 300 }}
      renderInput={(parameters) => (
        <TextField
          label={userInfo.isControl ? 'Collaborateurs' : 'Mes collaborateurs'}
          variant={'standard'}
          {...parameters}
          InputProps={{
            ...parameters.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
