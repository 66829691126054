import { useUserInfo } from 'components/User/UserProvider';
import { EmployeesEmployeeContractTypeChoices } from 'generated/graphql';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { parameterToString } from 'utils';

import DrawerHoverItem from './DrawerHoverItem';
import { DrawerStates, DrawerTitles, getUrlList } from './utils';

interface DrawerHoverMainMenuProps {
  anchorElement: HTMLElement | null | undefined;
  tabOpen: string;
  drawerStates: DrawerStates;
  handleClose: () => void;
  handleHover: () => void;
}

const DrawerHoverMainMenu = ({
  anchorElement,
  tabOpen,
  drawerStates,
  handleClose,
  handleHover,
}: DrawerHoverMainMenuProps) => {
  const userInfo = useUserInfo();
  const {
    employee,
    isControl,
    isComex,
    isMissionChief,
    isTmActivityMissionChief,
    isStandardUser,
    isSuperuser,
  } = userInfo;
  const contract_type = employee?.contractType;
  const weeklyContract = employee?.weeklyContract;
  const isFullTimeCollaborator =
    contract_type === EmployeesEmployeeContractTypeChoices.Cdi &&
    weeklyContract === 5 &&
    isStandardUser;
  const urlList = getUrlList();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const handlePath = (path: string): string => {
    if (params.has('mission_id')) {
      return `${path}?${parameterToString(params, 'mission_id')}`;
    }
    return `${path}`;
  };

  return (
    <>
      <DrawerHoverItem
        anchorElement={anchorElement}
        text={DrawerTitles.home}
        isOpen={tabOpen === DrawerTitles.home}
        handleMouseOver={handleClose}
      />
      <DrawerHoverItem
        anchorElement={anchorElement}
        text={DrawerTitles.phonebook}
        isOpen={tabOpen === DrawerTitles.phonebook}
        handleMouseOver={handleClose}
      />
      <DrawerHoverItem
        anchorElement={anchorElement}
        text={DrawerTitles.applications}
        isOpen={tabOpen === DrawerTitles.applications}
        handleMouseOver={handleClose}
      />
      <DrawerHoverItem
        anchorElement={anchorElement}
        text={DrawerTitles.myActivity}
        isOpen={tabOpen === DrawerTitles.myActivity}
        handleMouseOver={handleHover}
        handleMouseLeave={handleClose}
        subItems={[
          {
            name: DrawerTitles.myActivityReporting,
            to: handlePath(`${urlList['myActivityReporting']}`),
            isActive: drawerStates.myActivityReporting,
          },
          {
            name: DrawerTitles.myAbsences,
            to: handlePath(`${urlList['myAbsences']}`),
            isActive: drawerStates.myAbsences,
          },
          ...(isFullTimeCollaborator
            ? [
                {
                  name: DrawerTitles.myExceptionalActivities,
                  to: handlePath(`${urlList['myExceptionalActivities']}`),
                  isActive: drawerStates.myExceptionalActivities,
                },
              ]
            : []),
        ]}
      />
      <DrawerHoverItem
        anchorElement={anchorElement}
        text={DrawerTitles.adminEmployees}
        isOpen={tabOpen === DrawerTitles.adminEmployees}
        handleMouseOver={handleHover}
        handleMouseLeave={handleClose}
      />
      <DrawerHoverItem
        anchorElement={anchorElement}
        text={DrawerTitles.adminActivities}
        isOpen={tabOpen === DrawerTitles.adminActivities}
        handleMouseOver={handleHover}
        handleMouseLeave={handleClose}
      />
      <DrawerHoverItem
        anchorElement={anchorElement}
        text={DrawerTitles.billingClients}
        isOpen={tabOpen === DrawerTitles.billingClients}
        handleMouseOver={handleHover}
        handleMouseLeave={handleClose}
      />
      <DrawerHoverItem
        anchorElement={anchorElement}
        text={DrawerTitles.dashboard}
        isOpen={tabOpen === DrawerTitles.dashboard}
        handleMouseOver={handleClose}
      />
      <DrawerHoverItem
        anchorElement={anchorElement}
        text={DrawerTitles.activity}
        isOpen={tabOpen === DrawerTitles.activity}
        handleMouseOver={handleHover}
        handleMouseLeave={handleClose}
        subItems={[
          {
            name: DrawerTitles.collaborators,
            to: handlePath(`${urlList['collaborators']}`),
            isActive: drawerStates.collaborators,
          },
          ...(isTmActivityMissionChief || isControl || isComex || isSuperuser
            ? [
                {
                  name: DrawerTitles.activityMonitoring,
                  to: handlePath(`${urlList['activityMonitoring']}`),
                  isActive: drawerStates.activityMonitoring,
                },
              ]
            : []),
          ...(isMissionChief || isSuperuser
            ? [
                {
                  name: DrawerTitles.exeptionalActivities,
                  to: handlePath(`${urlList['exceptionalActivities']}`),
                  isActive: drawerStates.exeptionalActivities,
                },
              ]
            : []),
        ]}
      />
      <DrawerHoverItem
        anchorElement={anchorElement}
        text={DrawerTitles.revenue}
        isOpen={tabOpen === DrawerTitles.revenue}
        handleMouseOver={handleHover}
        handleMouseLeave={handleClose}
        subItems={[
          {
            name: DrawerTitles.estimatedRevenue,
            to: handlePath(`${urlList['estimatedRevenue']}`),
            isActive: drawerStates.estimatedRevenue,
          },
          {
            name: DrawerTitles.actualRevenue,
            to: handlePath(`${urlList['actualRevenue']}`),
            isActive: drawerStates.actualRevenue,
          },
          {
            name: DrawerTitles.billedRevenue,
            to: handlePath(`${urlList['billedRevenue']}`),
            isActive: drawerStates.billedRevenue,
          },
        ]}
      />
      <DrawerHoverItem
        anchorElement={anchorElement}
        text={DrawerTitles.billing}
        isOpen={tabOpen === DrawerTitles.billing}
        handleMouseOver={handleHover}
        handleMouseLeave={handleClose}
        subItems={[
          ...(isMissionChief || isComex || isControl || isSuperuser
            ? [
                {
                  name: DrawerTitles.purchaseOrders,
                  to: handlePath(`${urlList['purchaseOrders']}`),
                  isActive: drawerStates.purchaseOrders,
                },
              ]
            : []),
          {
            name: DrawerTitles.billingFollowUp,
            to: handlePath(`${urlList['billingFollowUp']}`),
            isActive: drawerStates.billingFollowUp,
          },
          {
            name: DrawerTitles.paymentFollowUp,
            to: handlePath(`${urlList['paymentFollowUp']}`),
            isActive: drawerStates.paymentFollowUp,
          },

          ...(isControl || isComex || isSuperuser
            ? [
                {
                  name: DrawerTitles.sectors,
                  to: handlePath(`${urlList['sectors']}`),
                  isActive: drawerStates.sectors,
                },
              ]
            : []),
        ].filter(Boolean)}
      />
      <DrawerHoverItem
        anchorElement={anchorElement}
        text={DrawerTitles.reporting}
        isOpen={tabOpen === DrawerTitles.reporting}
        handleMouseOver={handleHover}
        handleMouseLeave={handleClose}
        subItems={[
          {
            name: DrawerTitles.profitability,
            to: handlePath(`${urlList['profitability']}`),
            isActive: drawerStates.profitability,
          },
          ...(isControl || isComex || isSuperuser
            ? [
                {
                  name: DrawerTitles.analysis,
                  to: handlePath(`${urlList['analysis']}`),
                  isActive: drawerStates.analysis,
                },
              ]
            : []),
        ]}
      />
    </>
  );
};

export default DrawerHoverMainMenu;
