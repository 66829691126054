import MissionTypeIcon from 'components/commons/MissionTypeIcon';
import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import {
  ActivitiesActivityTypeChoices,
  ActivityNode,
  useCreateOrUpdateHrActivityMutation,
} from 'generated/graphql';
import { isEmpty } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useRouteMatch } from 'react-router-dom';
import urljoin from 'url-join';

import { ActivityTypeEnum } from '../../utils';
import HrActivityForm from './HrActivityForm';

interface HrActivityModalProps {
  open: boolean;
  onClose: () => void;
  activity?: ActivityNode;
}

type HrActivityFormData = {
  id?: string;
  name: string;
};

export const HrActivityModal = ({
  open,
  onClose,
  activity,
}: HrActivityModalProps): JSX.Element => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { handleSubmit, control, errors, watch, reset } =
    useForm<HrActivityFormData>({
      defaultValues: {
        name: activity?.name,
      },
    });
  const isEdit = !!activity;
  const createdOrUpdatedStr = isEdit ? 'mise à jour' : 'créée';

  const [createOrUpdateHrActivity] = useCreateOrUpdateHrActivityMutation({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    onCompleted: ({ createOrUpdateHrActivity }) => {
      const createdActivity = createOrUpdateHrActivity?.activity;

      enqueueSnackbar(
        `La mission « ${createdActivity?.name} » a bien été ${createdOrUpdatedStr}`,
        {
          variant: 'success',
        }
      );
      onClose();
      reset({
        name: createdActivity?.name || '',
      });

      createdActivity &&
        !isEdit &&
        history.push(urljoin(path, createdActivity.id as string));
    },
  });

  const createActivity = async ({ name }: HrActivityFormData) =>
    createOrUpdateHrActivity({
      variables: {
        name,
      },
    });

  const updateActivity = async ({ name }: HrActivityFormData) =>
    createOrUpdateHrActivity({
      variables: {
        id: activity?.id,
        name,
      },
    });

  return (
    <PolyModal
      open={open}
      onClose={onClose}
      polyDialogTitle={
        activity
          ? activity.name
          : `Création d'une nouvelle mission ${ActivityTypeEnum.HR}`
      }
      polyDialogIcon={
        <MissionTypeIcon type={ActivitiesActivityTypeChoices.Hr} tooltip />
      }
      handlePrimary={
        isEdit ? handleSubmit(updateActivity) : handleSubmit(createActivity)
      }
      isPrimaryButtonDisabled={!isEmpty(errors) || !watch('name')?.trim()}
      maxWidth="md"
    >
      <HrActivityForm control={control} errors={errors} />
    </PolyModal>
  );
};
