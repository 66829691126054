import { Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { EmployeeNode } from 'generated/graphql';
import moment from 'moment';
import { AVATAR_BLUE_PONGO_PATH, POLY_DATE } from 'poly-constants';
import React from 'react';
import { OpacitySuffix } from 'utils';

import { getSeniorityMessage } from '../utils';

interface MyProps {
  employee: EmployeeNode;
  isPhone?: boolean;
}

export default React.forwardRef(function PongoPlusText(
  { employee, isPhone }: MyProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const theme = useTheme();
  const componentShorteningTreshold = 10;

  return (
    <Tooltip
      ref={ref}
      title={moment(employee.hiringDate).format(POLY_DATE)}
      arrow
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{
          px: 1.5,
          py: 1,
          backgroundColor:
            theme.palette.info.light + OpacitySuffix.THIRTY_PERCENT,
          '&:hover': {
            backgroundColor:
              theme.palette.info.light + OpacitySuffix.FIFTY_PERCENT,
          },
          borderRadius: 2,
          width: 'fit-content',
        }}
      >
        <img
          src={AVATAR_BLUE_PONGO_PATH}
          alt="PongoBlueIcon"
          width={20}
          height={20}
        />
        <Stack direction="row">
          {(employee.firstName.length <= componentShorteningTreshold ||
            isPhone) && (
            <Typography variant="body2">
              <pre>{'Polynaute depuis '}</pre>
            </Typography>
          )}
          <Typography variant="bodySBold">
            {getSeniorityMessage(employee.hiringDate)}
          </Typography>
        </Stack>
      </Stack>
    </Tooltip>
  );
});
