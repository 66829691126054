import { Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import PageWrapper from 'components/commons/PageWrapper';
import PolyTitle from 'components/commons/PolyTitle';
import { useUserInfo } from 'components/User/UserProvider';
import { EmployeeNode } from 'generated/graphql';
import UserInfo from 'pages/UserPage/UserInfo';
import React from 'react';

import UserMissionsList from './UserMissionsList';
import UserTrophies from './UserTrophies';
import VersionDisplay from './VersionDisplay';

const UserPage = () => {
  const theme = useTheme();
  const { employee } = useUserInfo();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <PageWrapper noLimit={isPhone} large={!isPhone}>
      <PolyTitle sx={{ mb: 3.75 }}>Mon profil</PolyTitle>
      <Grid container spacing={3}>
        <VersionDisplay />
        <Grid item xs={12}>
          <UserInfo
            isEditable
            isUserPageView
            employee={employee as EmployeeNode}
          />
        </Grid>
        <Grid item xs={12} md={7.5}>
          <UserMissionsList employee={employee as EmployeeNode} />
        </Grid>
        <Grid item xs={12} md={4.5}>
          <UserTrophies employeeId={employee?.id || ''} />
        </Grid>
      </Grid>
    </PageWrapper>
  );
};

export default UserPage;
