import { Box, Button, SxProps, Typography } from '@mui/material';
import React from 'react';

import MissionTypeIcon from '../MissionTypeIcon';

interface MissionNameDisplayProps {
  mission: string;
  staffingRate?: number;
  type: string;
  isTmContracts: boolean;
  callBack?: () => void;
  haveBorder: boolean;
  customRadius?: string;
  sx?: SxProps;
  iconSx?: SxProps;
}

const MissionNameDisplay = (properties: MissionNameDisplayProps) => {
  const missionName = (
    <>
      <MissionTypeIcon
        sx={{
          mr: 1,
          fontSize: '18px',
          ...properties.iconSx,
        }}
        type={properties.type}
        isTmContracts={properties.isTmContracts}
        tooltip
      />
      <Typography variant="bodySBold" sx={properties.sx}>
        {properties.mission}
        {properties.staffingRate && ` (${properties.staffingRate}\u00A0%)`}
      </Typography>
    </>
  );

  return properties.callBack ? (
    <Box
      sx={{
        alignItems: 'center',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        width: 'fit-content',
        ...(properties.haveBorder && {
          background: 'background.default',
          border: '1px solid',
          borderColor: 'darkGrey.main',
          borderRadius: '5px',
        }),
        borderRadius: properties.customRadius,
        ...properties.sx,
      }}
    >
      <Button
        sx={{
          borderRadius: properties.customRadius,
          letterSpacing: 'normal',
          textTransform: 'none',
          paddingRight: '10px',
          paddingBottom: '4px',
          paddingTop: '4px',
        }}
        onClick={properties.callBack}
      >
        {missionName}
      </Button>
    </Box>
  ) : (
    <Typography
      sx={{
        alignItems: 'center',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        width: 'fit-content',
        ...(properties.haveBorder && {
          background: 'background.default',
          border: '1px solid',
          borderColor: 'darkGrey.main',
          borderRadius: '5px',
        }),
      }}
    >
      {missionName}
    </Typography>
  );
};

MissionNameDisplay.defaultProps = {
  isTmContracts: false,
  type: 'int',
  callBack: undefined,
  haveBorder: true,
};

export default MissionNameDisplay;
