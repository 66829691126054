import Typography from '@mui/material/Typography';
import PageWrapper from 'components/commons/PageWrapper';
import TabHeader from 'components/commons/TabHeader';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivityNode,
} from 'generated/graphql';
import _ from 'lodash';
import { Moment } from 'moment';
import ValidateTable from 'pages/ActivityPage/ValidateActivityMonitoringPage/ValidateTable';
import { PageTitles } from 'poly-constants';
import React from 'react';
import { useSelector } from 'store';

export interface DateRange {
  start: Moment;
  end: Moment;
}

export const buildDateRange = (
  date: Moment,
  startOffset = 7,
  endOffset = 2
): DateRange => {
  const end = date.clone().add(endOffset, 'month').endOf('month');
  const start = date.clone().subtract(startOffset, 'month').endOf('month');

  return { start: start, end: end };
};

const ValidateActivityMonitoringPage = () => {
  const currentMission = useSelector((state) => state.activity.currentMission);
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );
  const filteredMissions = currentMission?.id
    ? [currentMission]
    : selectedMissions;
  const areMissionsFiltered: boolean = filteredMissions.length > 0;
  const isTmContracts = _.some(
    filteredMissions,
    (mission) => mission.billingType === ActivitiesActivityBillingTypeChoices.Tm
  );
  return (
    <PageWrapper noLimit>
      {areMissionsFiltered && !isTmContracts ? (
        <NoTmActivityView filteredMissions={filteredMissions} />
      ) : (
        <ValidateTable />
      )}
    </PageWrapper>
  );
};

export default ValidateActivityMonitoringPage;

function NoTmActivityView({
  filteredMissions,
}: {
  filteredMissions: ActivityNode[];
}) {
  return (
    <>
      <TabHeader title={PageTitles.validateActivityMonitoringPage} />
      <Typography sx={{ marginTop: 3 }}>
        {filteredMissions.length === 1
          ? 'La mission sélectionnée n’est pas une mission régie.'
          : "Aucune des missions sélectionnées n'est une mission régie."}
      </Typography>
    </>
  );
}
