import DialogActions from '@mui/material/DialogActions';
import PongoButton from 'components/MUIOverload/PongoButton';
import React from 'react';

interface PolyDialogActionsProps {
  handlePrimary?: () => void;
  primaryButtonName?: string;
  isPrimaryButtonDisabled?: boolean;
  primaryButtonType?: 'button' | 'submit' | 'reset';
  handleSecondary?: () => void;
  secondaryButtonName?: string;
  isSecondaryButtonDisabled?: boolean;
  extraFirstButton?: JSX.Element;
}

export default function PolyDialogActions({
  handlePrimary,
  primaryButtonName,
  isPrimaryButtonDisabled = false,
  primaryButtonType,
  handleSecondary,
  secondaryButtonName,
  isSecondaryButtonDisabled = false,
  extraFirstButton,
}: PolyDialogActionsProps) {
  return (
    <DialogActions>
      {extraFirstButton}
      <PongoButton
        onClick={handleSecondary}
        disabled={isSecondaryButtonDisabled}
      >
        {secondaryButtonName ? secondaryButtonName : 'Annuler'}
      </PongoButton>
      <PongoButton
        type={primaryButtonType}
        variant={'contained'}
        color={'primary'}
        onClick={handlePrimary}
        disabled={isPrimaryButtonDisabled}
      >
        {primaryButtonName ? primaryButtonName : 'Enregistrer'}
      </PongoButton>
    </DialogActions>
  );
}
