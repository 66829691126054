import { Chip, SxProps } from '@mui/material';
import { ActivityNode } from 'generated/graphql';
import moment from 'moment';
import React from 'react';

interface MissionStatusChipProps {
  activity: ActivityNode;
  sx?: SxProps;
}

enum ChipStateEnum {
  PROGRESS,
  UNINITIALIZED,
  PASSED,
}

const ChipText = {
  [ChipStateEnum.PROGRESS]: 'En cours',
  [ChipStateEnum.UNINITIALIZED]: 'Non initialisée',
  [ChipStateEnum.PASSED]: 'Passée',
};

const ChipColor = {
  [ChipStateEnum.PROGRESS]: 'primary.light',
  [ChipStateEnum.UNINITIALIZED]: 'purple.main',
  [ChipStateEnum.PASSED]: 'darkGrey.darker',
};

export default function MissionStatusChip({
  activity,
  sx,
}: MissionStatusChipProps) {
  let chipState = ChipStateEnum.PROGRESS;
  if (!activity.billingInformation) {
    chipState = ChipStateEnum.UNINITIALIZED;
  }
  if (moment(activity.expirationDate).isBefore(moment.now())) {
    chipState = ChipStateEnum.PASSED;
  }

  return (
    <Chip
      label={ChipText[chipState]}
      color={'primary'}
      clickable={false}
      size={'medium'}
      sx={{
        height: '38px',
        fontSize: '20px',
        fontWeight: '700',
        borderRadius: '24px',
        px: 0.5,
        backgroundColor: ChipColor[chipState],
        color: chipState === ChipStateEnum.PROGRESS ? 'primary.main' : 'white',
        ...sx,
      }}
    />
  );
}
