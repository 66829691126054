import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Button,
  Checkbox,
  Stack,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { stickyHeaderSx } from 'components/commons/SX/commonSx';
import { useTableContext } from 'contexts/ValidateActivityMonitoring/TableContextProvider';
import {
  BillingBillingStatusChoices,
  useMonthWorkDaysQuery,
} from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import React from 'react';

const ValidateTableHeader = () => {
  const {
    displayedMonths,
    incrementBaseMonth,
    decrementBaseMonth,
    mode,
    billingCells,
    setSelectedBillingValidations,
    selectedBillingValidations,
    filterMissionsIds,
    filterClientIds,
    filterCollabIds,
  } = useTableContext();
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useMonthWorkDaysQuery({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });
  return (
    <TableHead sx={stickyHeaderSx}>
      <TableRow
        sx={{
          border: '1px solid',
          borderColor: 'orange.main',
        }}
      >
        <TableCell sx={{ width: '20%' }}>
          <Typography fontWeight={'bold'}>Mission</Typography>
        </TableCell>
        {_.map(displayedMonths, (month: Moment) => {
          const bcsInMonth = _.filter(billingCells, (bc) =>
            bc.month.isSame(month, 'month')
          );
          const numberOfBusinessDaysInThisMonth = _.find(
            data?.monthWorkDays,
            (value) => moment(value?.month).isSame(month, 'month')
          )?.workDays;

          const bcsToValidateInMonth = _.filter(bcsInMonth, (bc) => {
            if (!_.isEmpty(filterCollabIds)) {
              if (!_.some(filterCollabIds, (id) => id === bc.employee.id)) {
                return false;
              }
            }
            if (!_.isEmpty(filterMissionsIds)) {
              if (
                !_.some(filterMissionsIds, (id) => {
                  const isSameMission = id === bc.mission.id;
                  const isMainMission = id === bc.mission.mainActivity?.id;
                  return isSameMission || isMainMission;
                })
              ) {
                return false;
              }
            }

            if (!_.isEmpty(filterClientIds)) {
              if (
                !_.some(filterClientIds, (id) => {
                  const isSameClient =
                    id === bc.mission.billingInformation?.billingClient?.id;
                  const isSameMainClient =
                    id ===
                    bc.mission.mainActivity?.billingInformation?.billingClient
                      ?.id;
                  return isSameClient || isSameMainClient;
                })
              ) {
                return false;
              }
            }
            return (
              bc.billing?.status !== BillingBillingStatusChoices.Billed &&
              bc.billing?.status !== BillingBillingStatusChoices.Approved
            );
          });

          const monthCellHead = (
            <TableCell
              sx={{
                padding: 0,
                width: '10%',
                textTransform: 'capitalize',
              }}
              key={`header-${month.format('MMM')}`}
            >
              <Stack
                sx={{ height: 1 }}
                direction={'row'}
                alignItems={'center'}
                alignContent={'center'}
                justifyContent={'space-between'}
              >
                {_.first(displayedMonths) === month ? (
                  <Button
                    sx={{
                      minWidth: '10px',
                      width: '10px',
                      height: '1',
                      borderRadius: 0,
                      color: 'white',
                      backgroundColor: 'darkGrey.darker',
                    }}
                    aria-label={'Précédent'}
                    onClick={() => {
                      decrementBaseMonth();
                    }}
                  >
                    <NavigateBeforeIcon />
                  </Button>
                ) : (
                  <Box sx={{ minWidth: '10px', width: '10px', height: '1' }} />
                )}
                <Stack>
                  <Stack flexDirection={'row'}>
                    <Typography
                      fontWeight={
                        month.isSame(moment(), 'month') ? 'bold' : 'normal'
                      }
                    >
                      {month.format('MMM')}
                    </Typography>
                    <Typography color={'textSecondary'} ml={1}>
                      {numberOfBusinessDaysInThisMonth}j
                    </Typography>
                  </Stack>
                  {mode === 'validation' && (
                    <Checkbox
                      sx={{
                        width: '100%',
                        height: '10px',
                        borderRadius: 0,
                      }}
                      disabled={bcsToValidateInMonth.length === 0}
                      onClick={() => {
                        const unselectedBc = _.intersection(
                          [...selectedBillingValidations],
                          bcsToValidateInMonth
                        );
                        // if every billing cells in the month has been selected
                        if (_.isEqual(unselectedBc, bcsToValidateInMonth)) {
                          _.forEach(bcsToValidateInMonth, (element) =>
                            selectedBillingValidations.delete(element)
                          );
                        } else {
                          _.forEach(bcsToValidateInMonth, (element) =>
                            selectedBillingValidations.add(element)
                          );
                        }
                        setSelectedBillingValidations(
                          new Set(selectedBillingValidations)
                        );
                      }}
                    />
                  )}
                </Stack>
                {_.last(displayedMonths) === month ? (
                  <Button
                    sx={{
                      minWidth: '10px',
                      width: '10px',
                      height: '1',
                      borderRadius: 0,
                      color: 'white',
                      backgroundColor: 'darkGrey.darker',
                    }}
                    aria-label={'Précédent'}
                    onClick={() => {
                      incrementBaseMonth();
                    }}
                  >
                    <NavigateNextIcon />
                  </Button>
                ) : (
                  <Box sx={{ minWidth: '10px', width: '10px', height: '1' }} />
                )}
              </Stack>
            </TableCell>
          );

          if (_.last(displayedMonths) === month) {
            return (
              <React.Fragment key={`header-${month.format('MMM')}`}>
                {monthCellHead}
                <TableCell
                  sx={{
                    textAlign: 'center',
                    width: '10%',
                    backgroundColor: 'darkGrey.main',
                    textTransform: 'capitalize',
                  }}
                >
                  <Stack
                    direction={'row'}
                    spacing={1}
                    justifyContent={'center'}
                  >
                    <Typography fontWeight={'bold'}>Total</Typography>
                    <Typography color={'textSecondary'}>mission</Typography>
                  </Stack>
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: 'center',
                    width: '10%',
                    backgroundColor: 'darkGrey.main',
                    textTransform: 'capitalize',
                  }}
                >
                  <Stack
                    direction={'row'}
                    spacing={1}
                    justifyContent={'center'}
                  >
                    <Typography fontWeight={'bold'}>Total</Typography>
                    <Typography color={'textSecondary'}>année</Typography>
                  </Stack>
                </TableCell>
              </React.Fragment>
            );
          }
          return monthCellHead;
        })}
      </TableRow>
    </TableHead>
  );
};

export default ValidateTableHeader;
