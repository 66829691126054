import { Stack } from '@mui/material';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import TabHeader from 'components/commons/TabHeader';
import BillingClientAddButton from 'components/MissionFollowUp/BillingClient/BillingClientAddButton';
import BillingClientsExportButton from 'components/MissionFollowUp/BillingClient/BillingClientsExportButton';
import BillingClientTable from 'components/MissionFollowUp/BillingClient/BillingClientTable';
import BusinessClientAddButton from 'components/MissionFollowUp/BusinessClient/BusinessClientAddButton';
import BusinessClientTable from 'components/MissionFollowUp/BusinessClient/BusinessClientTable';
import SubTabsNavigation from 'components/Navigation/SubTabsNavigation/SubTabsNavigation';
import { useUserInfo } from 'components/User/UserProvider';
import {
  BillingClientNode,
  SectorNode,
  useAllBillingClientQuery,
  useAllBusinessClientsQuery,
  useGetAllSectorsQuery,
} from 'generated/graphql';
import _ from 'lodash';
import { PageTitles } from 'poly-constants';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'store';

export default function ClientManagementPage() {
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );
  const currentMission = useSelector((state) => state.activity.currentMission);
  const missionIds = currentMission?.id
    ? [currentMission.id]
    : _.map(selectedMissions, (mission) => mission.id);
  const {
    data: billingClientsData,
    loading: loadingBillingClients,
    refetch: refetchBillingClients,
  } = useAllBillingClientQuery({
    variables: {
      activityIds: missionIds,
    },
  });
  const {
    data: businessClientsData,
    loading: loadingBusinessClients,
    refetch: refetchBusinessClients,
  } = useAllBusinessClientsQuery();
  const clients = billingClientsData?.allBillingClient as BillingClientNode[];
  const { pathname } = useLocation();

  // we copy the clients object because data from Appollo is immutable
  const clientsCopy = _.cloneDeep(clients);

  _.map(clientsCopy, (client) => {
    const addresses = client.addresses;
    if (addresses && addresses.length > 1) {
      addresses.forEach(function (address, i) {
        if (addresses && address.id === client?.mainAddress?.id) {
          addresses.splice(i, 1);
          addresses.unshift(address);
        }
      });
    }
    client.addresses = addresses;
  });

  const { data: sectorData } = useGetAllSectorsQuery();
  const sectorList = sectorData?.allSectors as SectorNode[];
  const { isSuperuser, isControl } = useUserInfo();

  const billingClientsTab = {
    name: 'Clients à facturer'.toUpperCase(),
    to: 'billing_clients',
    icon: <></>,
    componentRendered: loadingBillingClients ? (
      <Stack mt={6}>
        <LoadingPlaceholder />
      </Stack>
    ) : (
      clientsCopy && (
        <BillingClientTable
          data={clientsCopy}
          sectorList={sectorList}
          refetch={refetchBillingClients}
        />
      )
    ),
  };

  const businessClientsTab = {
    name: 'Clients métier'.toUpperCase(),
    to: 'business_clients',
    icon: <></>,
    componentRendered: (
      <BusinessClientTable
        data={businessClientsData}
        loading={loadingBusinessClients}
        refetch={refetchBusinessClients}
      />
    ),
  };

  const isBillingClientsTabActive = pathname.includes('billing_clients');
  const isBusinessClientsTabActive = pathname.includes('business_clients');

  const isSuperuserOrControl = isSuperuser || isControl;

  const BillingClientsTabHeader = () => (
    <TabHeader
      title={PageTitles.billingClientsPage}
      actionComponents={
        isSuperuserOrControl ? (
          <>
            <BillingClientsExportButton />
            <BillingClientAddButton
              refetch={refetchBillingClients}
              sectorList={sectorList}
            />
          </>
        ) : undefined
      }
    />
  );

  const BusinessClientsTabHeader = () => (
    <TabHeader
      title={PageTitles.billingClientsPage}
      actionComponents={
        <BusinessClientAddButton refetch={refetchBusinessClients} />
      }
    />
  );

  return (
    <>
      {isBillingClientsTabActive && <BillingClientsTabHeader />}
      {isBusinessClientsTabActive && <BusinessClientsTabHeader />}
      <SubTabsNavigation
        variant="standard"
        titleSize="large"
        items={[billingClientsTab, businessClientsTab]}
      />
    </>
  );
}
