import Section from 'components/commons/Section';
import SectionTitle from 'components/commons/SectionTitle';
import TrophiesList from 'components/Phonebook/EmployeeCard/Trophies/TrophiesList';
import { TrophyNode, useAllEmployeeTrophiesQuery } from 'generated/graphql';
import React from 'react';

interface UserTrophiesProps {
  employeeId: string;
}

const UserTrophies = ({ employeeId }: UserTrophiesProps) => {
  const { data: trophies } = useAllEmployeeTrophiesQuery({
    variables: {
      employeeId: employeeId,
    },
  });

  return (
    <Section
      sx={{
        height: '250px',
      }}
    >
      <SectionTitle>Trophées</SectionTitle>
      <TrophiesList
        trophies={trophies?.employee?.trophies as TrophyNode[]}
        sx={{
          overflow: 'auto',
          width: '100%',
          aspectRatio: '3 / 4',
        }}
        showTitle={false}
      />
    </Section>
  );
};

export default UserTrophies;
