import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PushPinIcon from '@mui/icons-material/PushPin';
import { Box, Paper, Stack, Typography, useTheme } from '@mui/material';
import MissionStatusChip from 'components/commons/MissionStatusChip';
import PongoButton from 'components/MUIOverload/PongoButton';
import { ActivityNode } from 'generated/graphql';
import moment from 'moment';
import { POLY_DATE } from 'poly-constants';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setSelectedMission } from 'store/activity';
import { OpacitySuffix } from 'utils';

import { MissionTitle } from './MissionTitle';

interface MissionHeaderProps {
  mission: ActivityNode;
  isUnitialized?: boolean;
  onInitializeMission?: () => void;
  showStatusChip: boolean;
  showPinButton?: boolean;
}

function MissionHeader({
  mission,
  isUnitialized,
  onInitializeMission,
  showStatusChip,
  showPinButton,
}: MissionHeaderProps) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );

  const pinMission = () => {
    const newSelectedMissions = [
      ...selectedMissions.filter(
        (filteredMission) => filteredMission.id !== mission.id
      ),
    ];
    if (!selectedMissions.find((m) => m.id === mission.id)) {
      newSelectedMissions.push(mission);
    }
    dispatch(setSelectedMission(newSelectedMissions));
  };

  return (
    <Stack
      component={Paper}
      direction={'column'}
      gap={2}
      padding={2.5}
      sx={{
        bgcolor:
          theme.palette.tabHeaderBlue.light + OpacitySuffix.FORTY_PERCENT,
      }}
      elevation={0}
      borderRadius={2}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems="flex-start"
      >
        <Stack direction="row" gap={2} alignItems="center" flexWrap="wrap">
          <MissionTitle mission={mission} />
          <Stack direction="row" gap={2} alignItems="center">
            <Typography variant="h2" color={'darkGrey.darker'}>
              {mission?.client}
            </Typography>
            {showPinButton && (
              <PongoButton
                sx={{
                  width: '36px',
                  minWidth: '36px',
                  height: '36px',
                  p: 0,
                  borderRadius: 1,
                }}
                variant="contained"
                buttonStyle="secondary"
                startIcon={<PushPinIcon fontSize="large" />}
                onClick={pinMission}
              />
            )}
          </Stack>
        </Stack>
        {isUnitialized && (
          <PongoButton
            variant="contained"
            startIcon={<PlayCircleOutlineIcon />}
            onClick={onInitializeMission}
            sx={{ minWidth: 140 }}
          >
            <Box>Initialiser</Box>
          </PongoButton>
        )}
      </Stack>
      <Stack direction={'row'} justifyContent={'start'} gap={2}>
        <Typography color={'textSecondary'} sx={{ fontSize: '14px' }}>
          {moment(mission.startDate).format(POLY_DATE)} &gt;{' '}
          {moment(mission.expirationDate).format(POLY_DATE)}
        </Typography>
      </Stack>
      {showStatusChip && mission && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: '4px' }}>
          <MissionStatusChip activity={mission} />
        </Box>
      )}
    </Stack>
  );
}

export default MissionHeader;
