import { Search } from '@mui/icons-material';
import SyncIcon from '@mui/icons-material/Sync';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  createFilterOptions,
  InputAdornment,
  ListItem,
  Popper,
  PopperProps,
  TextField,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import DisplayMission from 'components/commons/DisplayMission';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivityNode,
  EmployeeNode,
  EmployeesEmployeeGradeChoices,
  useAllChiefedActivitiesQuery,
  useAllEmployeesForActivitiesListLazyQuery,
  useManagedActivityForMissionsFollowUpLazyQuery,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'store';
import { setCurrentMission, setSelectedMission } from 'store/activity';
import { setEmployeesForCurrentActivity } from 'store/users';
import { getMissionTypeString, isChiefOrDirectorOfActivity } from 'utils';

import MissionChips from './MissionChips';
import OverflowingMissionsDropdown from './OverflowingMissionsDropdown';
import UninitialisedMissionModal from './UninitialisedMissionModal';

const AutocompletePopper = (props: PopperProps): ReactElement => {
  return <Popper {...props} sx={{ paddingTop: 1 }} />;
};

function correctPathnameRedirection(pathname: string) {
  if (pathname.includes('/details/purchase_order')) {
    return pathname.replace('/details/purchase_order', '/purchase-orders');
  } else if (pathname.includes('/details/bill')) {
    return pathname.replace('/details/bill', '/billing-followup');
  } else if (pathname.includes('reporting/profitability/mission')) {
    return pathname.replace('/profitability/mission', '/profitability');
  }
  return pathname;
}

export const filterSearchbarOptions = createFilterOptions({
  stringify: (activity: ActivityNode): string => {
    const toMatchFields = [activity.name, activity.client];
    return _.join(toMatchFields, ' ');
  },
});

function AutocompleteInputRenderer(props: AutocompleteRenderInputParams) {
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <TextField
      {...props}
      placeholder={'Rechercher une mission'}
      variant="standard"
      sx={{
        backgroundColor: theme.palette.background.default,
        boxSizing: 'border-box',
        width: { xs: '100%', sm: 356 },
        height: { xs: 56, sm: 42 },
        borderRadius: 2,
        border: '3px solid',
        borderColor: isFocused
          ? theme.palette.info.light
          : theme.palette.background.default,
        input: {
          '&::placeholder': {
            opacity: isFocused ? 0.5 : 1,
          },
        },
        '& .MuiAutocomplete-clearIndicator': {
          mr: 1,
        },
      }}
      InputProps={{
        ...props.InputProps,
        disableUnderline: true,
        sx: {
          fontSize: theme.typography.bodyBold?.fontSize,
          fontWeight: theme.typography.bodyBold?.fontWeight,
          color: theme.palette.text.secondary,
          height: '100%',
        },
        startAdornment: (
          <InputAdornment sx={{ marginLeft: 2 }} position="start">
            <Search sx={{ color: theme.palette.text.secondary }} />
          </InputAdornment>
        ),
        onFocus: () => setIsFocused(true),
        onBlur: () => setIsFocused(false),
      }}
    />
  );
}

export default function SearchMissionBar() {
  const { enqueueSnackbar } = useSnackbar();
  const [currentMissionLocalState, setCurrentMissionLocalState] =
    useState<ActivityNode>({} as ActivityNode);
  const { employee, isControl } = useUserInfo();
  const activities = useSelector(
    (state) => state.activity.activities as ActivityNode[]
  );
  const isComex = employee?.grade === EmployeesEmployeeGradeChoices.Comex;
  const [missionModalVisibility, setMissionModalVisibility] = useState(false);
  const missionHasBeenInit = useRef(false);
  const currentMission = useSelector((state) => state.activity.currentMission);
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );

  const ownCurrentMissions = useSelector(
    (state) => state.activity.ownCurrentMissions
  );
  const otherCurrentMissions = useSelector(
    (state) => state.activity.otherCurrentMissions
  );
  const ownPastMissions = useSelector(
    (state) => state.activity.ownPastMissions
  );
  const otherPastMissions = useSelector(
    (state) => state.activity.otherPastMissions
  );

  const history = useHistory();
  const { pathname, search } = useLocation();
  const isDashboard = pathname.includes('/mission-followup/dashboard');
  const dispatch = useDispatch();

  const parameters = new URLSearchParams(search);
  const currentMissionId = parameters.get('mission_id') || '';

  const { data } = useAllChiefedActivitiesQuery({ skip: isControl });
  const userActivitiesAsChief = data?.allChiefedActivities;

  const setMissionHasBeenInit = (value: boolean) => {
    missionHasBeenInit.current = value;
  };

  const [getCurrentMissionDetails] =
    useManagedActivityForMissionsFollowUpLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const mission = data.activity as ActivityNode;
        dispatch(setCurrentMission(mission));
        setCurrentMissionLocalState(mission);
      },
    });

  const [getAllEmployees] = useAllEmployeesForActivitiesListLazyQuery({
    onCompleted: (data) => {
      if (data?.allEmployeesForActivities) {
        dispatch(
          setEmployeesForCurrentActivity(
            data.allEmployeesForActivities as EmployeeNode[]
          )
        );
      }
    },
  });

  const checkForUninitializedMission = (
    mission: ActivityNode,
    showInitializationModal = false
  ) => {
    if (!mission?.id) {
      return;
    }
    const isMissionUninitialized = mission?.id && !mission.billingInformation;

    if (isMissionUninitialized) {
      enqueueSnackbar("Mission non initialisée, veuillez l'initialiser", {
        variant: 'warning',
        preventDuplicate: true,
      });
      setMissionModalVisibility(!isDashboard && showInitializationModal);
    }
  };

  const changeCurrentMission = useCallback(
    (currentMissionId: string) => {
      if (
        currentMissionId &&
        currentMissionId !== currentMissionLocalState?.id
      ) {
        getCurrentMissionDetails({
          variables: { objectId: currentMissionId },
        });
        getAllEmployees({
          variables: { activityIds: [currentMissionId] },
        });
      } else if (!currentMissionId) {
        setCurrentMissionLocalState({} as ActivityNode);
        dispatch(setCurrentMission({} as ActivityNode));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getCurrentMissionDetails,
      dispatch,
      getAllEmployees,
      enqueueSnackbar,
      setMissionModalVisibility,
      activities,
      parameters,
    ]
  );

  const clearMissionFilter = () => {
    dispatch(setSelectedMission([]));
    removeCurrentMission();
  };

  const selectMission = (mission?: ActivityNode) => {
    if (
      mission?.id &&
      !_.map(
        selectedMissions,
        (selectedMission) => selectedMission.id
      ).includes(mission?.id)
    ) {
      const newSelectedMissions = [mission, ...selectedMissions];
      dispatch(setSelectedMission(newSelectedMissions));
      checkForUninitializedMission(mission);
    }
  };

  // Add a mission to the url
  const setNewCurrentMission = (mission?: ActivityNode) => {
    if (mission?.id) {
      parameters.set('mission_id', mission.id);
      checkForUninitializedMission(mission, true);
    }
    history.push({
      pathname: correctPathnameRedirection(pathname),
      search: parameters.toString(),
    });
  };

  // Remove a mission from the url
  const removeCurrentMission = () => {
    parameters.delete('mission_id');
    history.push({
      pathname: correctPathnameRedirection(pathname),
      search: parameters.toString(),
    });
  };

  // Remove a single mission from selected missions
  const removeMission = (mission?: ActivityNode) => {
    if (mission && mission?.id === currentMission.id) {
      removeCurrentMission();
    }
    const missionsToKeep = _.filter(
      selectedMissions,
      (selectedMission) => selectedMission.id !== mission?.id
    );
    dispatch(setSelectedMission(missionsToKeep));
  };

  //when refreshing, if there is a current mission, we add the missionChip
  useEffect(() => {
    if (currentMission?.id && !isDashboard && selectedMissions.length === 0) {
      const newSelectedMissions = [
        currentMission,
        ...selectedMissions.filter(
          (mission) => mission.id !== currentMission.id
        ),
      ];
      dispatch(setSelectedMission(newSelectedMissions));
    }
    // eslint-disable-next-line
  }, [currentMission]);

  useEffect(() => {
    changeCurrentMission(currentMissionId);
    // eslint-disable-next-line
  }, [currentMissionId]);

  const [isModalContentLoading, setIsModalContentLoading] = useState(true);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          width: 'calc(100% - 20px)',
          overflow: 'hidden',
          paddingLeft: 6,
          columnGap: 1.5,
          w: 1,
        }}
      >
        <Autocomplete
          value={null}
          getOptionLabel={() => ''}
          autoHighlight
          autoComplete
          PopperComponent={AutocompletePopper}
          popupIcon={false}
          filterOptions={filterSearchbarOptions}
          groupBy={(option) => {
            const isCurrent = moment().isBefore(option?.expirationDate);
            if (
              (!isControl || isComex) &&
              (isChiefOrDirectorOfActivity(option, employee as EmployeeNode) ||
                userActivitiesAsChief?.some(
                  (activitiesAsChief) => activitiesAsChief.id === option.id
                ))
            ) {
              return `Mes missions ${isCurrent ? '' : 'passées'}`;
            }
            return `Missions ${isCurrent ? 'courantes' : 'passées'}`;
          }}
          id="search-select-mission"
          options={_.filter(
            [
              ...ownCurrentMissions,
              ...otherCurrentMissions,
              ...ownPastMissions,
              ...otherPastMissions,
            ],
            (mission) => {
              return !_.some(selectedMissions, mission);
            }
          )}
          onChange={(event, value: ActivityNode | null) => {
            selectMission(value || undefined);
            setNewCurrentMission(value || undefined);
          }}
          renderOption={(props, option: ActivityNode) => (
            <Box component={'li'} {...props} key={'option-' + option.id}>
              <DisplayMission
                mission={option}
                isClientDisplayed
                missionTooltip
                textMaxWidth={260}
              />
            </Box>
          )}
          renderInput={AutocompleteInputRenderer}
        />
        {selectedMissions.length > 3 && (
          <OverflowingMissionsDropdown count={selectedMissions.length}>
            {_.map(selectedMissions, (mission, index) => {
              const isCurrentMission = currentMission.id === mission.id;
              return (
                <ListItem key={`list-item-${mission.id}-${index}`}>
                  <MissionChips
                    activity={mission}
                    removeMission={() => removeMission(mission)}
                    setNewCurrentMission={() => setNewCurrentMission(mission)}
                    removeCurrentMission={removeCurrentMission}
                    isCurrentMission={isCurrentMission}
                  />
                </ListItem>
              );
            })}
          </OverflowingMissionsDropdown>
        )}
        {selectedMissions.length > 0 && selectedMissions.length < 4 && (
          <>
            {_.map(selectedMissions, (mission, index) => {
              const isCurrentMission = currentMission.id === mission.id;
              return (
                <MissionChips
                  key={`mission-chip-item-${mission.id}-${index}`}
                  activity={mission}
                  removeMission={() => removeMission(mission)}
                  setNewCurrentMission={() => setNewCurrentMission(mission)}
                  removeCurrentMission={removeCurrentMission}
                  isCurrentMission={isCurrentMission}
                  textMaxWidth={110}
                />
              );
            })}
          </>
        )}
        {selectedMissions.length > 0 && (
          <PongoButton
            sx={{ borderRadius: 4 }}
            variant="contained"
            uppercase={false}
            buttonStyle="input"
            size="small"
            endIcon={<SyncIcon />}
            onClick={clearMissionFilter}
            autoHeight
          >
            Réinitialiser
          </PongoButton>
        )}
        {!!currentMission?.id && (
          <UninitialisedMissionModal
            missionModalVisibility={missionModalVisibility}
            isModalContentLoading={isModalContentLoading}
            handleClose={() => setMissionModalVisibility(false)}
            getMissionTypeString={getMissionTypeString}
            mission={currentMission}
            setMissionHasBeenInit={setMissionHasBeenInit}
            setIsModalContentLoading={setIsModalContentLoading}
          />
        )}
      </Box>
    </Box>
  );
}
