import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import {
  Box,
  Stack,
  Step,
  StepButton,
  Stepper,
  Typography,
} from '@mui/material';
import { Modality } from 'components/MissionFollowUp/BillDetail/MissionInitialization/ModalitiesMissionForm/Types';
import {
  AddressType,
  getStringAddress,
} from 'components/MissionFollowUp/DisplayAddress';
import PolyDialogActions from 'components/MUIOverload/PolyDialog/DefaultComponents/PolyDialogActions';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivitiesActivityTypeChoices,
  ActivityNode,
  BillingBillingModalitiesBillingTypeChoices,
  BillingClientNode,
  BusinessClientNode,
  EmployeeNode,
  useAllBillingClientQuery,
  useAllBusinessClientsQuery,
  useAllEmployeesForBackOfficeQuery,
  useComexQuery,
  useCreateExternalActivityMutation,
  useCreateOrUpdateSubActivitiesMutation,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import {
  NextButtonName,
  PreviousButtonName,
  SaveButtonName,
} from 'poly-constants';
import { default as React, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { graphQlDateFormatter } from 'utils';

import ExternalActivityContactForm from './ExternalActivityContactForm';
import ExternalActivityInfoForm from './ExternalActivityInfoForm';
import ExternalActivityModalitiesForm from './ExternalActivityModalitiesForm';
import ExternalActivitySubActivitiesForm from './ExternalActivitySubActivitiesForm';

interface ExternalActivityFormProps {
  closeModal?: (activity?: ActivityNode) => void;
  activityType: ActivitiesActivityBillingTypeChoices;
}

export const ExternalActivityForm = ({
  closeModal,
  activityType,
}: ExternalActivityFormProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [billingClientsArray, setBillingClientsArray] = useState<
    BillingClientNode[]
  >([]);
  const [businessClientsArray, setBusinessClientsArray] = useState<
    BusinessClientNode[]
  >([]);
  const isTM = activityType === ActivitiesActivityBillingTypeChoices.Tm;
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  useAllBillingClientQuery({
    onCompleted: (data) => {
      if (data.allBillingClient) {
        setBillingClientsArray(data.allBillingClient as BillingClientNode[]);
      }
    },
  });

  useAllBusinessClientsQuery({
    onCompleted: (data) => {
      if (data.allBusinessClients) {
        setBusinessClientsArray(
          data.allBusinessClients as BusinessClientNode[]
        );
      }
    },
  });

  const [employees, setEmployees] = useState<EmployeeNode[]>([]);
  useAllEmployeesForBackOfficeQuery({
    onCompleted: (data) => {
      if (data?.allEmployeesForBackOffice) {
        setEmployees(data.allEmployeesForBackOffice as EmployeeNode[]);
      }
    },
  });

  const [allComexEmployees, setAllComexEmployees] = useState<EmployeeNode[]>(
    []
  );
  useComexQuery({
    onCompleted: (data) => {
      if (data?.comex) {
        setAllComexEmployees(data.comex as EmployeeNode[]);
      }
    },
  });

  const billingModalitiesDefault: Modality[] = [];
  if (billingModalitiesDefault.length === 0) {
    billingModalitiesDefault.push({
      billingType: BillingBillingModalitiesBillingTypeChoices.Email,
      billingEmail: '',
      billingName: '',
    });
  }

  type SubActivityFormData = ActivityNode & {
    period: [Date, Date];
  };

  const defaultValues = {
    name: '',
    type: ActivitiesActivityTypeChoices.Ext,
    billingType: activityType,
    isTm: isTM,
    client: '',
    selectedBillingClient: undefined as unknown as BillingClientNode,
    selectedBusinessClient: undefined as unknown as BusinessClientNode,
    refMarket: '',
    selectedDirector: undefined as unknown as EmployeeNode,
    selectedChiefs: [] as EmployeeNode[],
    selectedLeadDevs: [] as EmployeeNode[],
    startDate: moment().format('YYYY-MM-DD'),
    expirationDate: moment().format('YYYY-MM-DD'),
    description: '',
    techStack: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    accountantName: '',
    accountantEmail: '',
    accountantPhone: '',
    billingModalities: billingModalitiesDefault || ([] as Modality[]),
    subActivities: [] as SubActivityFormData[],
  };

  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
    criteriaMode: 'firstError',
  });

  const { errors, handleSubmit, control, trigger } = form;

  const [createExternalActivityMutation] = useCreateExternalActivityMutation({
    onError: (error) => {
      if (error.message === 'Invalid format for phone number') {
        enqueueSnackbar(`Mauvais format de numéro de téléphone`, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      }
    },
    onCompleted: (data) => {
      const activity = data.createExternalActivity?.activity as ActivityNode;
      enqueueSnackbar(`La mission « ${activity.name} » a bien été créée`, {
        variant: 'success',
      });
      closeModal?.(activity);
    },
  });

  const [createOrUpdateSubActivitiesMutation] =
    useCreateOrUpdateSubActivitiesMutation({
      onError: (error) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      },
    });

  function isFormRight() {
    return _.isEmpty(errors);
  }

  const [address, setAddress] = useState({} as AddressType);

  const onSubmit = async (submitValues: typeof defaultValues) => {
    let modalities = [];

    modalities = submitValues.billingModalities;
    modalities.forEach((modality: Modality) => {
      modality.billingAddress = getStringAddress(
        submitValues.selectedBillingClient
      );
    });

    const { data } = await createExternalActivityMutation({
      variables: {
        name: submitValues.name || '',
        type: ActivitiesActivityTypeChoices.Ext,
        billingType: activityType,
        client: submitValues.selectedBusinessClient?.name || '',
        billingClientId: submitValues.selectedBillingClient?.id || '',
        businessClientId: submitValues.selectedBusinessClient?.id || '',
        refMarket: submitValues.refMarket || '',
        directorId: submitValues.selectedDirector?.id || '',
        chiefsIds: _.flatMap(submitValues.selectedChiefs, 'id') || '',
        leadDevsIds: _.flatMap(submitValues.selectedLeadDevs, 'id') || '',
        startDate: moment(submitValues.startDate).format('YYYY-MM-DD'),
        expirationDate: moment(submitValues.expirationDate).format(
          'YYYY-MM-DD'
        ),
        description: submitValues.description || '',
        techStack: submitValues.techStack || '',
        contactName: submitValues.contactName || '',
        contactEmail: submitValues.contactEmail || '',
        contactPhone: submitValues.contactPhone || '',
        accountantName: submitValues.accountantName || '',
        accountantEmail: submitValues.accountantEmail || '',
        accountantPhone: submitValues.accountantPhone || '',
        billingModalities: modalities,
      },
    });
    if (data?.createExternalActivity?.activity?.id) {
      await createOrUpdateSubActivitiesMutation({
        variables: {
          mainActivityId: data?.createExternalActivity?.activity?.id,
          subActivities: _.map(submitValues.subActivities, (subActivity) => {
            return {
              name: subActivity.name,
              id: subActivity.id,
              startDate: graphQlDateFormatter(
                subActivity.period?.[0] ||
                  moment(submitValues.startDate).format('YYYY-MM-DD')
              ),
              expirationDate: graphQlDateFormatter(
                subActivity.period?.[1] ||
                  moment(submitValues.expirationDate).format('YYYY-MM-DD')
              ),
            };
          }),
        },
      });
    }
  };

  const steps = [
    {
      label: 'Informations',
      display: true,
      icon: <InfoOutlinedIcon />,
      validationFields: [
        'name',
        'selectedBillingClient',
        'selectedBusinessClient',
        'selectedDirector',
        'selectedChiefs',
        'startDate',
        'expirationDate',
      ],
      additionalValidation: true,
      form: (
        <ExternalActivityInfoForm
          employees={employees}
          allComexEmployees={allComexEmployees}
          billingClients={billingClientsArray}
          businessClients={businessClientsArray}
          setAddress={setAddress}
        />
      ),
    },
    {
      label: 'Contact',
      display: true,
      icon: <CallOutlinedIcon />,
      validationFields: [
        'accountantName',
        'accountantEmail',
        'contactName',
        'contactEmail',
      ],
      additionalValidation: true,
      form: <ExternalActivityContactForm />,
    },
    {
      label: 'Modalités de facturation',
      display: true,
      icon: <SendOutlinedIcon />,
      validationRegEx: /^billingModalities/,
      additionalValidation: true,
      form: <ExternalActivityModalitiesForm address={address} />,
    },
    {
      label: 'Découpage',
      display: isTM,
      icon: <ContentCutOutlinedIcon />,
      form: <ExternalActivitySubActivitiesForm />,
    },
  ];

  const filteredSteps = steps.filter((step) => step.display);

  const totalSteps = () => {
    return filteredSteps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const validateStep = async (): Promise<boolean> => {
    const currentFieldsRefKeys = Object.keys(control.fieldsRef.current);
    const validationFields = filteredSteps[activeStep].validationFields;
    const validationRegEx = filteredSteps[activeStep].validationRegEx;
    const additionalValidation =
      !!filteredSteps[activeStep].additionalValidation;
    let result = true;

    if (Array.isArray(validationFields)) {
      result = await trigger(filteredSteps[activeStep].validationFields);
    } else if (validationRegEx) {
      for (const fieldRefKey of currentFieldsRefKeys) {
        if (validationRegEx.test(fieldRefKey)) {
          const currentValidation = await trigger(fieldRefKey);
          if (!currentValidation) {
            result = false;
          }
        }
      }
    }
    return result && additionalValidation;
  };

  const handleNext = async () => {
    if (filteredSteps.length > 1 && filteredSteps.length > activeStep + 1) {
      if (await validateStep()) {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const navTabs = (
    <>
      <Box sx={{ width: '100%', my: 2, minWidth: 852 }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {filteredSteps.map((step, index) => (
            <Step
              key={step.label}
              completed={completed[index]}
              sx={{ display: step.display ? 'inherit' : 'none' }}
            >
              <StepButton
                aria-label={`stepper-tab-${index}`}
                color="inherit"
                onClick={handleStep(index)}
              >
                <Typography
                  textTransform={'uppercase'}
                  variant="bodyS"
                  color={index === activeStep ? 'primary' : 'inherit'}
                >
                  {step.label}
                </Typography>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
    </>
  );

  const actionButtons = (
    <>
      <PolyDialogActions
        key={`actions-${activeStep}`}
        primaryButtonName={isLastStep() ? SaveButtonName : NextButtonName}
        secondaryButtonName={PreviousButtonName}
        handlePrimary={handleNext}
        handleSecondary={handleBack}
        primaryButtonType={isLastStep() ? 'submit' : 'button'}
        isPrimaryButtonDisabled={
          isLastStep()
            ? !isFormRight()
            : !filteredSteps[activeStep].additionalValidation
        }
        isSecondaryButtonDisabled={activeStep === 0}
      />
    </>
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        {navTabs}
        {filteredSteps.map((step, index) => (
          <Box
            key={`mission-form-step-${index}`}
            sx={{ display: activeStep === index ? 'inherit' : 'none' }}
          >
            {step.form}
          </Box>
        ))}
        <Stack direction={'row'} justifyContent={'right'}>
          {actionButtons}
        </Stack>
      </form>
    </FormProvider>
  );
};
