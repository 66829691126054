import PageWrapper from 'components/commons/PageWrapper';
import TabHeader from 'components/commons/TabHeader';
import ActivityIndicatorTable from 'components/Reporting/Indicators/ActivityIndicatorTable/ActivityIndicatorTable';
import { useDashboardRevenueAnalysisQuery } from 'generated/graphql';
import { PageTitles } from 'poly-constants';
import React from 'react';

const DashboardPage = () => {
  return (
    <PageWrapper noLimit>
      <TabHeader
        title={PageTitles.dashboardPage}
        initializeMission={true}
        ignoreMissionHeader
      />
      <ActivityIndicatorTable queryHook={useDashboardRevenueAnalysisQuery} />
    </PageWrapper>
  );
};

export default DashboardPage;
