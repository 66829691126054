import { Box, Grid, Typography } from '@mui/material';
import InfoTooltip from 'components/commons/InfoTooltip';
import AddItem from 'components/commons/ItemInteractions/AddItem/AddItem';
import SectionTitle from 'components/commons/SectionTitle';
import { getModalityName } from 'components/MissionFollowUp/BillDetail/BillDetail/utils';
import ModalityEmail from 'components/MissionFollowUp/BillDetail/MissionInitialization/ModalitiesMissionForm/ModalitiesTemplates/ModalityEmail';
import ModalityPostal from 'components/MissionFollowUp/BillDetail/MissionInitialization/ModalitiesMissionForm/ModalitiesTemplates/ModalityPostal';
import ModalityUpload from 'components/MissionFollowUp/BillDetail/MissionInitialization/ModalitiesMissionForm/ModalitiesTemplates/ModalityUpload';
import ModalityCard from 'components/MissionFollowUp/BillDetail/MissionInitialization/ModalitiesMissionForm/ModalityCard';
import { Modality } from 'components/MissionFollowUp/BillDetail/MissionInitialization/ModalitiesMissionForm/Types';
import {
  AddressType,
  DisplayAddress,
  getStringAddressFromAddress,
} from 'components/MissionFollowUp/DisplayAddress';
import GridItem from 'components/MissionFollowUp/GridItem';
import GridSubTitle from 'components/MissionFollowUp/GridSubTitle';
import { BillingBillingModalitiesBillingTypeChoices } from 'generated/graphql';
import _ from 'lodash';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

interface ExternalActivityModalitiesFormProps {
  address: AddressType;
}

export default function ExternalActivityModalitiesForm({
  address,
}: ExternalActivityModalitiesFormProps): JSX.Element {
  const { watch, control } = useFormContext();

  const { fields, remove, append } = useFieldArray({
    control,
    keyName: 'keyName',
    name: 'billingModalities',
  });

  const addressTitle = (
    <span>
      Adresse de facturation
      <InfoTooltip
        title={
          'Contactez la compta pour changer cette adresse: compta-clients@polyconseil.fr'
        }
      />
    </span>
  );

  function addModality() {
    append({
      billingType: BillingBillingModalitiesBillingTypeChoices.Email,
      billingAddress: getStringAddressFromAddress(address),
    });
  }

  return (
    <Grid container spacing={0} sx={{ paddingBottom: 2 }}>
      <GridSubTitle customspacingtop={20}>
        <SectionTitle variant="h3Bold">
          Modalités de l&apos;envoi de la facturation
        </SectionTitle>
      </GridSubTitle>

      {Object.keys(address).length > 0 && (
        <Grid container item xs={12}>
          <GridSubTitle>{addressTitle}</GridSubTitle>
          <GridItem sizegrid={12}>
            <DisplayAddress
              addressInformations={address}
              displayTabTitle={true}
            />
          </GridItem>
        </Grid>
      )}

      <Box sx={{ width: '100%', py: 1, px: 1.5 }}>
        {fields.length === 0 && (
          <Typography color={'error'} my={4}>
            Il est obligatoire d&apos;ajouter au moins une modalité de
            facturation.
          </Typography>
        )}

        {_.map(fields, (modality, index) => {
          if (!modality || !modality.billingType) {
            return <div key={`modality-${modality.keyName}`} />;
          }
          return (
            <ModalityCard
              selectName={getModalityName(index, 'billingType')}
              selector={modality.billingType}
              key={`modality-${modality.keyName}`}
              index={index}
              onDeleteModality={remove}
            >
              <Grid container item xs={12}>
                {watch(getModalityName(index, 'billingType')) ===
                  BillingBillingModalitiesBillingTypeChoices.Email && (
                  <ModalityEmail
                    modality={modality as Modality}
                    index={index}
                  />
                )}
                {watch(getModalityName(index, 'billingType')) ===
                  BillingBillingModalitiesBillingTypeChoices.Upload && (
                  <ModalityUpload
                    index={index}
                    modality={modality as Modality}
                  />
                )}
                {watch(getModalityName(index, 'billingType')) ===
                  BillingBillingModalitiesBillingTypeChoices.Postal && (
                  <ModalityPostal
                    index={index}
                    modality={modality as Modality}
                  />
                )}
              </Grid>
            </ModalityCard>
          );
        })}
        <AddItem onClick={addModality} description={'Nouvelle modalité'} />
      </Box>
    </Grid>
  );
}
