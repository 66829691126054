import {
  Skeleton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { Buffer } from 'buffer';
import AvatarInitial from 'components/commons/AvatarInitial/AvatarInitial';
import PolyTooltip from 'components/MUIOverload/PolyTooltip';
import OutOfRangeTableCell from 'components/Revenue/Tables/RevenueTableCells/OutOfRangeTableCell';
import { useTableContext } from 'contexts/ValidateActivityMonitoring/TableContextProvider';
import {
  ActivityMonitoringNode,
  ActivityNode,
  AllPurchaseOrderQuery,
  EmployeeNode,
  useAllPurchaseOrderQuery,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { AssignmentType } from 'pages/ActivityPage/utils';
import { BillingCell } from 'pages/ActivityPage/ValidateActivityMonitoringPage/Rows/BillingCell';
import { POLY_DATE_MONTH } from 'poly-constants';
import React from 'react';
import { displayEmployeeNode } from 'utils';

interface EmployeeRowProps {
  assignment: AssignmentType;
  mission: ActivityNode;
  employeeActivityMonitorings?: ActivityMonitoringNode[];
}

function getIsSameYearAndMonth(date1: moment.Moment, date2: moment.Moment) {
  return date1.month() === date2.month() && date1.year() === date2.year();
}

function getCollaboratorDaysDeclaredForCurrentMonth(
  month: moment.Moment,
  activityMonitorings?: ActivityMonitoringNode[]
) {
  if (!activityMonitorings) {
    return 0;
  }

  const currentMonthActivityMonitorings = _.filter(
    activityMonitorings,
    (activityMonitoring) => {
      return getIsSameYearAndMonth(moment(activityMonitoring.date), month);
    }
  );

  const totalMonthDaysDeclared = _.sumBy(
    currentMonthActivityMonitorings,
    'timeSpent'
  );

  return totalMonthDaysDeclared;
}

function isEmployeeInPurchaseOrders(
  employee: EmployeeNode,
  purchaseOrdersQuery?: AllPurchaseOrderQuery
) {
  return Boolean(
    purchaseOrdersQuery?.allPurchaseOrder?.purchaseOrders?.some(
      (purchaseOrder) => {
        return purchaseOrder?.collaborators?.some((collaborator) => {
          return collaborator.collaborator?.id === employee.id;
        });
      }
    )
  );
}

export const EmployeeRow = ({
  assignment,
  mission,
  employeeActivityMonitorings,
}: EmployeeRowProps) => {
  const { displayedMonths, sumOfActivities, isComputing } = useTableContext();
  const isSubMission = !!mission.mainActivity;
  const employee = assignment.employee as EmployeeNode;
  const key = `${Buffer.from(mission.id, 'base64').toString().split(':')[1]}-${
    Buffer.from(employee.id, 'base64').toString().split(':')[1]
  }`;
  const { data: allPurchaseOrders } = useAllPurchaseOrderQuery({
    variables: {
      activityIds: mission?.mainActivity?.id
        ? [mission.id, mission?.mainActivity?.id]
        : [mission.id],
    },
  });

  const isValidationNeeded = isEmployeeInPurchaseOrders(
    employee,
    allPurchaseOrders
  );

  return (
    <TableRow>
      <TableCell
        sx={{
          border: '1px solid',
          borderColor: 'darkGrey.main',
          borderWidth: '0 1px 0 0',
        }}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          spacing={1}
          pl={isSubMission ? 1 : 0}
        >
          <PolyTooltip title={displayEmployeeNode(employee, false)}>
            <span>
              <AvatarInitial employee={employee} />
            </span>
          </PolyTooltip>
          <Typography>{displayEmployeeNode(employee)}</Typography>
        </Stack>
      </TableCell>
      {_.map(displayedMonths, (month) => {
        let cellMonth;
        const collaboratorDaysDeclared =
          getCollaboratorDaysDeclaredForCurrentMonth(
            month,
            employeeActivityMonitorings
          );
        if (
          moment(assignment.beginningDate).startOf('month').isAfter(month) ||
          moment(assignment.expirationDate).endOf('month').isBefore(month)
        ) {
          cellMonth = (
            <OutOfRangeTableCell
              title={
                "Le collaborateur n'est pas assigné sur cette mission à cette date"
              }
            />
          );
        } else {
          cellMonth = (
            <BillingCell
              employee={employee}
              mission={mission}
              isValidationNeeded={isValidationNeeded}
              month={month}
              collaboratorDaysDeclared={collaboratorDaysDeclared}
            />
          );
        }
        // TODO: get total columns from the back
        if (_.last(displayedMonths) === month) {
          return (
            <React.Fragment
              key={`${employee.id}-${mission.id}-${month.format(
                POLY_DATE_MONTH
              )}`}
            >
              {cellMonth}
              <TableCell
                sx={{ backgroundColor: 'darkGrey.main' }}
                align={'center'}
              >
                <Typography fontWeight={'bold'}>
                  {isComputing.timeSpentTotal
                    ? dataSkeleton
                    : sumOfActivities[key]?.totalSum || 0}
                </Typography>
              </TableCell>
              <TableCell
                sx={{ backgroundColor: 'darkGrey.main' }}
                align={'center'}
              >
                <Typography fontWeight={'bold'}>
                  {isComputing.timeSpentYear
                    ? dataSkeleton
                    : sumOfActivities[key]?.yearlySum || 0}
                </Typography>
              </TableCell>
            </React.Fragment>
          );
        }
        return (
          <React.Fragment
            key={`${employee.id}-${mission.id}-${month.format(
              POLY_DATE_MONTH
            )}`}
          >
            {cellMonth}
          </React.Fragment>
        );
      })}
    </TableRow>
  );
};

export const dataSkeleton = (
  <Skeleton
    variant="text"
    sx={{
      mx: 6,
    }}
  />
);
