import { Check, Close, PlaylistAddCheck } from '@mui/icons-material';
import { Stack } from '@mui/material';
import PongoButton from 'components/MUIOverload/PongoButton';
import {
  BillingCellType,
  useTableContext,
} from 'contexts/ValidateActivityMonitoring/TableContextProvider';
import {
  ActivityNode,
  BillingEmployeeInput,
  BillingNode,
  EmployeeNode,
  useUpdateBillingsMutation,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { getBillingKey } from 'pages/ActivityPage/ValidateActivityMonitoringPage/ValidateTable';
import React from 'react';
import { graphQlDateFormatter } from 'utils';

function ValidateButton() {
  const {
    setMode,
    billings,
    billingCells,
    setBillingCells,
    selectedBillingValidations,
    setSelectedBillingValidations,
  } = useTableContext();
  const { enqueueSnackbar } = useSnackbar();

  const [updateBillings, { loading }] = useUpdateBillingsMutation({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    onCompleted: (data) => {
      enqueueSnackbar(
        `Vous avez validé ${selectedBillingValidations.size} temps`,
        {
          variant: 'success',
        }
      );
      setMode(undefined);
      setSelectedBillingValidations(new Set<BillingCellType>());
      _.forEach(data.updateBillings?.billings, (billing) => {
        const key = getBillingKey(
          billing.employee as EmployeeNode,
          billing.activity as ActivityNode,
          moment(billing.dateBilling)
        );
        billings[key] = billing as BillingNode;
        billingCells[key].billing = billing as BillingNode;
      });
      setBillingCells(_.clone(billingCells));
    },
  });

  const validateBillings = async () => {
    const billings: BillingEmployeeInput[] = _.map(
      [...selectedBillingValidations],
      (billingCell: BillingCellType) => {
        return {
          billingId: billingCell.billing?.id,
          dateBilling: graphQlDateFormatter(
            billingCell.month.clone().endOf('month')
          ),
          employeeId: billingCell.employee.id,
          activityId: billingCell.mission.id,
          timeForTmContracts: billingCell.billing?.timeForTmContracts || 0,
        };
      }
    );
    await updateBillings({
      variables: {
        billings: billings,
        validating: true,
      },
    });
  };

  return (
    <Stack direction="row" gap={2}>
      <PongoButton
        onClick={() => {
          setSelectedBillingValidations(new Set<BillingCellType>());
          setMode(undefined);
        }}
        startIcon={<Close />}
        variant={'contained'}
        buttonStyle={'secondary'}
        disabled={loading}
      >
        Annuler
      </PongoButton>
      <PongoButton
        onClick={async () => {
          if (!loading) {
            await validateBillings();
          }
        }}
        variant={'contained'}
        buttonStyle={'primary'}
        startIcon={<Check />}
        loading={loading}
      >
        {selectedBillingValidations.size > 0
          ? `Valider ${selectedBillingValidations.size} temps`
          : 'Valider'}
      </PongoButton>
    </Stack>
  );
}

function EnterValidationModeButton() {
  const { mode, setMode } = useTableContext();
  return (
    <PongoButton
      onClick={() => (!mode ? setMode('validation') : setMode(undefined))}
      variant={mode === 'validation' ? 'contained' : 'outlined'}
      color={'success'}
      disabled={mode && mode !== 'validation'}
      startIcon={<PlaylistAddCheck />}
    >
      {'Validation groupée'}
    </PongoButton>
  );
}

export default ValidateButton;
export { EnterValidationModeButton };
