import { TableCell, TableRow, Typography } from '@mui/material';
import { useTableContext } from 'contexts/ValidateActivityMonitoring/TableContextProvider';
import { ActivityMonitoringNode, ActivityNode } from 'generated/graphql';
import _ from 'lodash';
import { AssignmentType } from 'pages/ActivityPage/utils';
import { EmployeeRow } from 'pages/ActivityPage/ValidateActivityMonitoringPage/Rows/EmployeeRow';
import React from 'react';

interface MissionRowProps {
  mission: ActivityNode;
  assignments: AssignmentType[];
  missionActivityMonitorings: ActivityMonitoringNode[] | undefined;
}

export const MissionRow = ({
  mission,
  assignments,
  missionActivityMonitorings,
}: MissionRowProps) => {
  const { filterCollabIds } = useTableContext();

  const isSubMission = !!mission.mainActivity;

  const displayMissionRow = (mission: ActivityNode, isSubMission: boolean) => {
    return (
      <TableRow>
        <TableCell sx={{ border: '1px solid', borderColor: 'darkGrey.dark' }}>
          <Typography
            fontWeight={'bold'}
            pl={isSubMission ? 1 : 0}
            color={isSubMission ? 'textSecondary' : ''}
          >
            {mission.name}
            {!isSubMission && mission.billingInformation?.billingClient && (
              <Typography component={'span'} color={'textSecondary'}>
                {` \\ ${
                  mission.billingInformation?.billingClient?.corporateName || ''
                }`}
              </Typography>
            )}
          </Typography>
        </TableCell>
        <TableCell
          sx={{ border: '1px solid', borderColor: 'darkGrey.dark' }}
          colSpan={7}
        />
      </TableRow>
    );
  };

  if (!_.isEmpty(mission.subActivities)) {
    return displayMissionRow(mission, false);
  }

  return (
    <React.Fragment>
      {displayMissionRow(mission, isSubMission)}
      {_.map(assignments, (assignment: AssignmentType) => {
        const isFiltered =
          filterCollabIds.length > 0 &&
          !_.includes(filterCollabIds, assignment.employee.id);
        if (isFiltered) {
          return;
        }
        const employeeActivityMonitorings = missionActivityMonitorings
          ? _.filter(missionActivityMonitorings, (activityMonitoring) => {
              return activityMonitoring.employee.id === assignment.employee.id;
            })
          : undefined;
        return (
          <EmployeeRow
            key={`${assignment.employee.id}-${mission.id}`}
            assignment={assignment as AssignmentType}
            mission={mission}
            employeeActivityMonitorings={employeeActivityMonitorings}
          />
        );
      })}
    </React.Fragment>
  );
};
