import { Add } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import StaffingSelect from 'components/AdminPage/StaffingSelect';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import Section from 'components/commons/Section';
import SectionTitle from 'components/commons/SectionTitle';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import {
  ActivityNode,
  AssignmentNode,
  useAllAssignmentsForActivityForAdminQuery,
} from 'generated/graphql';
import { SECTION_BODY_HEIGHT } from 'poly-constants';
import React, { useMemo, useState } from 'react';

import StaffingModal from '../StaffingModal';
import {
  ActivityStatusEnum,
  getActivityStatus,
  getFilteredAssigments,
  StaffingStatusEnum,
} from '../utils';
import ActivityStaffingTable from './ActivityStaffingTable';
import NoAssignments from './NoAssignments';

interface ActivityStaffingProps {
  activity: ActivityNode;
  isEditable?: boolean;
}

const statusToDefaultFilterMap = {
  [ActivityStatusEnum.PAST]: StaffingStatusEnum.ALL,
  [ActivityStatusEnum.ONGOING]: StaffingStatusEnum.CURRENT,
  [ActivityStatusEnum.FUTURE]: StaffingStatusEnum.FUTURE,
};

const ActivityStaffing = ({ activity, isEditable }: ActivityStaffingProps) => {
  const ADMIN_BACK_OFFICE_FLAG = useEnvironmentVariable('admin_back_office');
  const status = getActivityStatus(activity.startDate, activity.expirationDate);
  const [staffingFilter, setStaffingFilter] = useState<StaffingStatusEnum>(
    statusToDefaultFilterMap[status]
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAssignment, setSelectedAssignment] =
    useState<AssignmentNode>();
  const {
    data,
    loading,
    refetch: refetchAllAssignments,
  } = useAllAssignmentsForActivityForAdminQuery({
    variables: {
      activityId: activity.id,
    },
  });
  const filteredAssignments = useMemo(() => {
    const assignments = data?.allAssignmentsForActivityForBackOffice || [];
    return getFilteredAssigments(
      assignments as AssignmentNode[],
      staffingFilter
    );
  }, [data?.allAssignmentsForActivityForBackOffice, staffingFilter]);
  return (
    <Section>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <SectionTitle>Affectations</SectionTitle>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <StaffingSelect
            staffingFilter={staffingFilter}
            setStaffingFilter={setStaffingFilter}
          />
          {ADMIN_BACK_OFFICE_FLAG && isEditable && (
            <PongoButton
              variant="contained"
              size="small"
              startIcon={<Add />}
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Ajouter
            </PongoButton>
          )}
        </Stack>
      </Stack>

      {loading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: SECTION_BODY_HEIGHT,
          }}
        >
          <LoadingPlaceholder />
        </Box>
      ) : (
        <Box
          sx={{
            height: SECTION_BODY_HEIGHT,
          }}
        >
          {filteredAssignments.length > 0 && (
            <ActivityStaffingTable
              assignments={filteredAssignments as AssignmentNode[]}
              isEditable={isEditable}
              setIsOpen={setIsModalOpen}
              setAssignment={setSelectedAssignment}
            />
          )}
          {filteredAssignments.length === 0 && (
            <NoAssignments activeFilter={staffingFilter} />
          )}
        </Box>
      )}
      <StaffingModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        activity={activity}
        assignment={selectedAssignment}
        setAssignment={setSelectedAssignment}
        refetchAllAssignments={refetchAllAssignments}
      />
    </Section>
  );
};

export default ActivityStaffing;
