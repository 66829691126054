import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Stack } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';

import PolyTitle from '../PolyTitle';

interface PolyHeaderInterface {
  children: React.ReactNode;
  goBackLocation?: Location;
  sx?: SxProps;
}
export default function PolyHeader({
  children,
  goBackLocation,
  sx,
}: PolyHeaderInterface) {
  const history = useHistory();
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      spacing={1}
      sx={{ mb: 3, ...sx }}
    >
      {goBackLocation && (
        <IconButton
          aria-label="back"
          onClick={() =>
            // We use a normal go back behavior when the user has already navigated in the app. If not, we use goBackLocation as a fallback.
            // The navigator's landing page counts in the history, so we check if the history length is lesser than or equal to 2
            history.length <= 2
              ? history.push(goBackLocation)
              : history.goBack()
          }
          size="large"
        >
          <ArrowBackIcon />
        </IconButton>
      )}
      <PolyTitle>{children}</PolyTitle>
    </Stack>
  );
}
