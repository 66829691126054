import { GridColDef, GridToolbar } from '@mui/x-data-grid-pro';
import {
  ActivityStatusEnum,
  getActivityStatus,
  getActivityStatusChip,
} from 'components/AdminPage/utils';
import ActivityIndicator, {
  indicatorToColorMap,
} from 'components/commons/ActivityIndicator';
import MuixTable from 'components/commons/MuixTable';
import { getUrlList } from 'components/Navigation/DesktopNavbar/utils';
import {
  AllActivitiesForRevenueAnalysisQuery,
  DashboardRevenueAnalysisQuery,
  IndicatorChoices,
  useAllActivitiesForRevenueAnalysisQuery,
  useDashboardRevenueAnalysisQuery,
} from 'generated/graphql';
import moment from 'moment';
import { EMPTY_FIELD, POLY_DATE } from 'poly-constants';
import React from 'react';
import { useHistory } from 'react-router-dom';
import urljoin from 'url-join';

import CommentTooltip from './CommentTooltip';
import Profit from './Profit';

const INDICATOR_VALUE_OPTIONS = Object.values(indicatorToColorMap).map(
  ({ text }) => text
);

const defaultColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Mission',
    type: 'string',
    minWidth: 150,
    flex: 2,
    valueGetter: (params) => params.value || EMPTY_FIELD,
  },
  {
    field: 'client',
    headerName: 'Client',
    type: 'string',
    minWidth: 150,
    flex: 1,
    valueGetter: (params) => params.value || EMPTY_FIELD,
  },
  {
    field: 'latestIndicator.clientSatisfaction',
    headerName: 'Statut Client',
    type: 'singleSelect',
    flex: 1,
    valueOptions: INDICATOR_VALUE_OPTIONS,
    valueGetter: ({ row: { latestIndicator } }) =>
      latestIndicator?.clientSatisfaction
        ? indicatorToColorMap[
            latestIndicator.clientSatisfaction as IndicatorChoices
          ].text
        : null,
    renderCell: ({ row: { latestIndicator } }) =>
      latestIndicator?.clientSatisfaction ? (
        <ActivityIndicator indicator={latestIndicator.clientSatisfaction} />
      ) : (
        EMPTY_FIELD
      ),
  },
  {
    field: 'latestIndicator.efficiency',
    headerName: 'Statut Équipe',
    type: 'singleSelect',
    flex: 1,
    valueOptions: INDICATOR_VALUE_OPTIONS,
    valueGetter: ({ row: { latestIndicator } }) =>
      latestIndicator?.efficiency
        ? indicatorToColorMap[latestIndicator.efficiency as IndicatorChoices]
            .text
        : null,
    renderCell: ({ row: { latestIndicator } }) =>
      latestIndicator?.efficiency ? (
        <ActivityIndicator indicator={latestIndicator.efficiency} />
      ) : (
        EMPTY_FIELD
      ),
  },
  {
    field: 'activityProfit.totalRevenue',
    headerName: 'CA Total',
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    flex: 1,
    valueGetter: ({ row: { activityProfit } }) => activityProfit.totalRevenue,
    renderCell: ({ row: { activityProfit } }) => (
      <Profit profit={activityProfit.totalRevenue} />
    ),
  },
  {
    field: 'activityProfit.margin',
    headerName: 'Renta Totale',
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    flex: 1,
    valueGetter: ({ row: { activityProfit } }) => activityProfit.margin,
    renderCell: ({ row: { activityProfit } }) => (
      <Profit profit={activityProfit.margin} highlightProfit />
    ),
  },
  {
    field: 'latestIndicator.createdAt',
    headerName: 'Dernière modification',
    type: 'date',
    flex: 1,
    valueGetter: ({ row: { latestIndicator } }) =>
      latestIndicator?.createdAt
        ? new Date(latestIndicator.createdAt)
        : undefined,
    renderCell: ({ row: { latestIndicator } }) =>
      latestIndicator?.createdAt
        ? moment(latestIndicator.createdAt).format(POLY_DATE)
        : EMPTY_FIELD,
  },
  {
    field: 'latestIndicator.comment',
    type: 'actions',
    maxWidth: 80,
    valueGetter: ({ row: { latestIndicator } }) => latestIndicator?.comment,
    renderCell: ({ row }) =>
      row.latestIndicator?.comment ? (
        <CommentTooltip comment={row.latestIndicator?.comment} />
      ) : (
        EMPTY_FIELD
      ),
  },
];

const statusColumn: GridColDef = {
  field: 'expirationDate',
  headerName: 'Statut',
  align: 'center',
  type: 'singleSelect',
  flex: 1,
  valueOptions: Object.values(ActivityStatusEnum),
  valueGetter: ({ row, value }) => {
    return getActivityStatus(row.startDate, value);
  },
  renderCell: ({ value }) => getActivityStatusChip(value),
  sortComparator: (v1, v2) => {
    const order = Object.values(ActivityStatusEnum);
    return order.indexOf(v1) - order.indexOf(v2);
  },
};

type ActivityIndicatorTable = {
  queryHook:
    | typeof useAllActivitiesForRevenueAnalysisQuery
    | typeof useDashboardRevenueAnalysisQuery;
};

function isAllActivitiesQueryResult(
  data:
    | AllActivitiesForRevenueAnalysisQuery
    | DashboardRevenueAnalysisQuery = {}
): data is AllActivitiesForRevenueAnalysisQuery {
  return 'allActivitiesForRevenueAnalysis' in data;
}

export default function ActivityIndicatorTable({
  queryHook,
}: ActivityIndicatorTable): JSX.Element {
  const history = useHistory();
  const urlList = getUrlList();
  const { data: activities, loading } = queryHook();

  const isAllActivitiesResult = isAllActivitiesQueryResult(activities);
  const rows = isAllActivitiesResult
    ? activities?.allActivitiesForRevenueAnalysis
    : activities?.dashboardRevenueAnalysis;

  const columns = defaultColumns.slice();
  !isAllActivitiesResult && columns.splice(-1, 0, statusColumn);

  return (
    <MuixTable
      rows={rows || []}
      columns={columns}
      loading={loading}
      rowSelection={false}
      pageSizeOptions={[10, 15, 25, 50]}
      pagination
      autoHeight
      ignoreDiacritics
      disableColumnReorder
      density={isAllActivitiesResult ? 'compact' : 'comfortable'}
      initialState={{
        pinnedColumns: { left: ['name'], right: ['expirationDate'] },
        preferencePanel: { open: false },
        sorting: {
          sortModel: [
            { field: 'expirationDate', sort: 'asc' },
            { field: 'name', sort: 'asc' },
          ],
        },
        pagination: {
          paginationModel: { pageSize: 25, page: 0 },
        },
      }}
      onRowClick={({ id }) =>
        history.push(urljoin(urlList.adminActivities, id as string))
      }
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
      sx={{
        '& .MuiDataGrid-cell': {
          ':focus': {
            outline: 'none',
          },
        },
        '& .MuiDataGrid-columnHeader': {
          ':focus , :focus-within': {
            outline: 'none',
          },
        },
        '.MuiDataGrid-row:hover': {
          cursor: 'pointer',
          backgroundColor: 'primary.light',
        },
        '.MuiDataGrid-row.Mui-hovered': {
          backgroundColor: 'primary.light',
        },
        '.MuiBadge-badge': {
          display: 'none',
        },
        borderRadius: '8px',
      }}
    />
  );
}
