import { GridColDef, GridToolbar } from '@mui/x-data-grid-pro';
import AvatarNameAndOccupationStack from 'components/commons/AvatarNameAndOccupationStack/AvatarNameAndOccupationStack';
import MuixTable from 'components/commons/MuixTable';
import { GradeChip } from 'components/Phonebook/EmployeeCard/GradeChip';
import { EmployeeGradeTextChoices } from 'components/Phonebook/utils';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import {
  AssignmentNode,
  EmployeesEmployeeGradeChoices,
} from 'generated/graphql';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { EMPTY_FIELD, POLY_DATE } from 'poly-constants';
import React from 'react';

import { AssignmentStatusTextChoices, getAssignmentStatusChip } from '../utils';

interface ActivityStaffingTableProps {
  assignments: AssignmentNode[];
  isEditable?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAssignment: React.Dispatch<
    React.SetStateAction<AssignmentNode | undefined>
  >;
}

const ActivityStaffingTable = ({
  assignments,
  isEditable,
  setIsOpen,
  setAssignment,
}: ActivityStaffingTableProps) => {
  const ADMIN_BACK_OFFICE_FLAG = useEnvironmentVariable('admin_back_office');
  const columns: GridColDef[] = [
    {
      field: 'employee',
      headerName: 'Collaborateur',
      flex: 2,
      minWidth: 300,
      valueGetter: (params) =>
        `${params.row.employee.firstName} ${params.row.employee.lastName}`,
      renderCell: (params) => {
        return <AvatarNameAndOccupationStack employee={params.row.employee} />;
      },
    },
    {
      field: 'beginningDate',
      headerName: 'Début',
      type: 'date',
      flex: 1,
      minWidth: 130,
      valueGetter: (params) =>
        params.value ? new Date(params.value) : undefined,
      renderCell: (params) =>
        params.value ? moment(params.value).format(POLY_DATE) : EMPTY_FIELD,
    },
    {
      field: 'expirationDate',
      headerName: 'Fin',
      type: 'date',
      flex: 1,
      minWidth: 130,
      valueGetter: (params) =>
        params.value ? new Date(params.value) : undefined,
      renderCell: (params) =>
        params.value ? moment(params.value).format(POLY_DATE) : EMPTY_FIELD,
    },
    {
      field: 'grade',
      headerName: 'Grade',
      type: 'singleSelect',
      valueOptions: Object.values(EmployeeGradeTextChoices),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        params.row.employee.grade
          ? EmployeeGradeTextChoices[
              params.row.employee.grade as EmployeesEmployeeGradeChoices
            ]
          : EMPTY_FIELD,
      renderCell: (params) => {
        return (
          <GradeChip
            employee={params.row.employee}
            displayAllGrades
            showLabel
          />
        );
      },
    },
    {
      field: 'staffingRate',
      headerName: 'Taux (%)',
      type: 'number',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) =>
        params.row.staffingRate
          ? `${params.row.staffingRate}\u00A0%`
          : EMPTY_FIELD,
    },
    {
      field: 'status',
      headerName: 'Statut',
      type: 'singleSelect',
      valueOptions: Object.values(AssignmentStatusTextChoices),
      flex: 1,
      minWidth: 150,
      align: 'center',
      valueGetter: (params) => getAssignmentStatusChip(params.row).label,
      renderCell: (params) => getAssignmentStatusChip(params.row).jsx,
      sortComparator: (v1, v2) => {
        const order = Object.values(AssignmentStatusTextChoices);
        return order.indexOf(v1) - order.indexOf(v2);
      },
    },
  ];
  return (
    <MuixTable
      ignoreDiacritics
      containerWidth={'100%'}
      containerHeight={'100%'}
      rows={assignments}
      columns={columns}
      pagination={true}
      pageSizeOptions={[25, 50, 100]}
      onRowClick={(item) => {
        if (!ADMIN_BACK_OFFICE_FLAG) {
          return;
        }
        const assignment = item.row as AssignmentNode;
        if (assignment) {
          if (isEditable) {
            setAssignment(assignment);
            setIsOpen(true);
          } else {
            enqueueSnackbar('Opération non autorisée', { variant: 'error' });
          }
        } else {
          enqueueSnackbar('Une erreur est survenue', { variant: 'error' });
        }
      }}
      initialState={{
        pinnedColumns: { left: ['employee'], right: ['status'] },
        sorting: {
          sortModel: [{ field: 'status', sort: 'asc' }],
        },
        pagination: {
          paginationModel: { pageSize: 25, page: 0 },
        },
      }}
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
      sx={{
        '& .MuiDataGrid-cell': {
          ':focus': {
            outline: 'none',
          },
        },
        '& .MuiDataGrid-columnHeader': {
          ':focus , :focus-within': {
            outline: 'none',
          },
        },
        '.MuiDataGrid-row:hover': {
          cursor: 'pointer',
          backgroundColor: 'primary.light',
        },
        '.MuiDataGrid-row.Mui-hovered': {
          backgroundColor: 'primary.light',
        },
        borderRadius: '8px',
      }}
      rowSelection={false}
    />
  );
};

export default ActivityStaffingTable;
