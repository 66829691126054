import PageWrapper from 'components/commons/PageWrapper';
import PolySwitch from 'components/Navigation/PolySwitch';
import AccessWrapper from 'components/User/AccessWrapper';
import { EmployeeOccupations, useUserInfo } from 'components/User/UserProvider';
import { EmployeesEmployeeGradeChoices } from 'generated/graphql';
import ClientManagementPage from 'pages/MissionFollowUp/ClientManagementPage/ClientManagementPage';
import PaymentFollowUp from 'pages/MissionFollowUp/PaymentFollowUpPage/PaymentFollowUp';
import React from 'react';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import urlJoin from 'url-join';

import ManagementControlBills from './ManagementControlBills';
import ManagerBillsPage from './ManagerBillsPage';
import MissionBillingPage from './MissionBillingPage';
import PurchaseOrdersPage from './PurchaseOrdersPage';
import SectorPage from './SectorPage/SectorPage';

const MissionFollowUp = () => {
  const { path } = useRouteMatch();
  const { employee, isStandardUser } = useUserInfo();

  const homeComponent =
    employee && isStandardUser ? ManagerBillsPage : ManagementControlBills;

  return (
    <PageWrapper noLimit>
      <PolySwitch>
        <Redirect exact from={path} to={urlJoin(path, '/billing-followup')} />
        <AccessWrapper
          occupations={[EmployeeOccupations.CONTROL, EmployeeOccupations.DG]}
          restrictMissionChief
        >
          <PolySwitch>
            <Route
              exact
              path={urlJoin(path, 'billing-followup')}
              component={homeComponent}
            />
            <Route
              exact
              path={urlJoin(path, 'payments-followup')}
              component={PaymentFollowUp}
            />
            <Route
              path={urlJoin(path, 'details')}
              component={MissionBillingPage}
            />
            <Route
              path={urlJoin(path, 'purchase-orders')}
              component={PurchaseOrdersPage}
            />
            <Route path={urlJoin(path, 'billing-clients')}>
              <AccessWrapper
                occupations={[EmployeeOccupations.CONTROL]}
                grades={[EmployeesEmployeeGradeChoices.Comex]}
                restrictMissionChief
              >
                <ClientManagementPage />
              </AccessWrapper>
            </Route>
            <Route path={urlJoin(path, 'client-sectors')}>
              <AccessWrapper
                occupations={[EmployeeOccupations.CONTROL]}
                grades={[EmployeesEmployeeGradeChoices.Comex]}
              >
                <SectorPage />
              </AccessWrapper>
            </Route>
          </PolySwitch>
        </AccessWrapper>
      </PolySwitch>
    </PageWrapper>
  );
};

export default MissionFollowUp;
