import AddIcon from '@mui/icons-material/Add';
import { Box, Stack } from '@mui/material';
import ActivitiesTable from 'components/AdminPage/AdminActivities/ActivitiesTable';
import { ExternalActivityModal } from 'components/AdminPage/AdminActivities/ExternalActivityModal';
import { HrActivityModal } from 'components/AdminPage/AdminActivities/HrActivityModal/HrActivityModal';
import { InternalActivityModal } from 'components/AdminPage/AdminActivities/InternalActivityModal/InternalActivityModal';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import PageWrapper from 'components/commons/PageWrapper';
import PolyHeader from 'components/commons/PolyHeader';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivityNode,
  useAllActivitiesForAdminQuery,
} from 'generated/graphql';
import { enqueueSnackbar } from 'notistack';
import { DATA_LOADING_ERROR_MESSAGE, PageTitles } from 'poly-constants';
import React, { useState } from 'react';

import NewActivityMenu from './NewActivityMenu';

const AdminActivitiesPage = () => {
  const ADMIN_BACK_OFFICE_FLAG = useEnvironmentVariable('admin_back_office');
  const { data, loading } = useAllActivitiesForAdminQuery({
    onError: () => {
      enqueueSnackbar(DATA_LOADING_ERROR_MESSAGE, {
        variant: 'error',
      });
    },
  });
  const activities = data?.allActivitiesForBackOffice || [];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openNewExternalActivityModal, setOpenNewExternalActivityModal] =
    useState<boolean>(false);
  const [openNewInternalActivityModal, setOpenNewInternalActivityModal] =
    useState<boolean>(false);
  const [externalActivityType, setExternalActivityType] =
    useState<ActivitiesActivityBillingTypeChoices>(
      ActivitiesActivityBillingTypeChoices.Package
    );
  const [openNewCftActivityModal, setOpenNewCftActivityModal] =
    useState<boolean>(false);
  const [openNewHrActivityModal, setOpenNewHrActivityModal] =
    useState<boolean>(false);

  const handleClickAddMission = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <PageWrapper noLimit>
      <Stack direction="row" justifyContent="space-between">
        <PolyHeader>{PageTitles.adminActivitiesPage}</PolyHeader>
        {ADMIN_BACK_OFFICE_FLAG && !loading && (
          <>
            <PongoButton
              variant={'contained'}
              buttonStyle={'primary'}
              onClick={handleClickAddMission}
              size={'medium'}
              startIcon={<AddIcon />}
            >
              Ajouter une mission
            </PongoButton>
            <NewActivityMenu
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              setOpenNewExternalActivityModal={(
                type: ActivitiesActivityBillingTypeChoices
              ) => {
                setExternalActivityType(type);
                setOpenNewExternalActivityModal(true);
              }}
              setOpenNewInternalActivityModal={setOpenNewInternalActivityModal}
              setOpenNewCftActivityModal={setOpenNewCftActivityModal}
              setOpenNewHrActivityModal={setOpenNewHrActivityModal}
            />
          </>
        )}
      </Stack>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            height: '600px',
            alignItems: 'center',
          }}
        >
          <LoadingPlaceholder />
        </Box>
      ) : (
        <ActivitiesTable activities={activities as ActivityNode[]} />
      )}
      <InternalActivityModal
        open={openNewInternalActivityModal}
        onClose={() => setOpenNewInternalActivityModal(false)}
        title="Création d'une nouvelle mission interne"
      />
      <InternalActivityModal
        open={openNewCftActivityModal}
        onClose={() => setOpenNewCftActivityModal(false)}
        title="Création d'un nouvel appel d'offre"
        isCftActivity
      />
      <HrActivityModal
        open={openNewHrActivityModal}
        onClose={() => setOpenNewHrActivityModal(false)}
      />
      <ExternalActivityModal
        open={openNewExternalActivityModal}
        onClose={() => setOpenNewExternalActivityModal(false)}
        activityType={externalActivityType}
      />
    </PageWrapper>
  );
};

export default AdminActivitiesPage;
