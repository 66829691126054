import { Search } from '@mui/icons-material';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { useUserInfo } from 'components/User/UserProvider';
import { useTableContext } from 'contexts/ValidateActivityMonitoring/TableContextProvider';
import { BillingClientNode } from 'generated/graphql';
import React from 'react';

export default function ClientFilterAm() {
  const { allClients, setFilterClientIds } = useTableContext();
  const { isControl, isSuperuser } = useUserInfo();

  if (allClients.length === 0) {
    return <React.Fragment />;
  }

  return (
    <Autocomplete
      autoHighlight
      autoComplete
      id="filter-select-collab"
      options={allClients}
      onChange={(event, value) => {
        const client = value as BillingClientNode;
        if (client) {
          setFilterClientIds([client.id]);
        } else {
          setFilterClientIds([]);
        }
      }}
      getOptionLabel={(option) => {
        if (option) {
          return option.corporateName || '';
        }
        return '';
      }}
      sx={{ width: 300 }}
      renderInput={(parameters) => (
        <TextField
          label={isControl || isSuperuser ? 'Clients' : 'Mes clients'}
          variant={'standard'}
          {...parameters}
          InputProps={{
            ...parameters.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
