import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useTableContext } from 'contexts/ValidateActivityMonitoring/TableContextProvider';
import { ActivityNode } from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { MissionRow } from 'pages/ActivityPage/ValidateActivityMonitoringPage/Rows/MissionRow';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'store';

import { getCompressedAssignmentWithMaxRange } from '../utils';

interface ValidateTableBodyProps {
  dataActivity: ActivityNode[] | undefined;
}

export default function ValidateTableBody({
  dataActivity,
}: ValidateTableBodyProps) {
  const {
    missions,
    filterMissionsIds,
    filterCollabIds,
    filterClientIds,
    dateRange,
    setFilterMissionsIds,
  } = useTableContext();

  const missionId = useSelector((state) => state.activity.currentMission.id);

  const subMissions: {
    [key: string]: ActivityNode[];
  } = useMemo(
    () =>
      _.groupBy(
        missions as ActivityNode[],
        (mission) => mission.mainActivity?.id
      ),
    [missions]
  );

  const filteredMissions = useMemo(() => {
    // We are sorting the activities in order to get the activities followed by their subactivities
    let orderedMissions: ActivityNode[] = [];
    _.flatMap(missions as ActivityNode[], (mission) => {
      if (!mission.mainActivity) {
        orderedMissions = _.concat(
          orderedMissions,
          mission,
          subMissions[mission.id] || []
        );
      }
    });
    if (!_.isEmpty(filterMissionsIds)) {
      orderedMissions = _.filter(orderedMissions, (mission) => {
        return _.some(
          filterMissionsIds,
          (item) => item === mission.id || item === mission.mainActivity?.id
        );
      });
    }
    if (!_.isEmpty(filterClientIds)) {
      orderedMissions = _.filter(orderedMissions, (mission) => {
        return _.some(
          filterClientIds,
          (clientId) =>
            clientId === mission.billingInformation?.billingClient?.id ||
            clientId ===
              mission.mainActivity?.billingInformation?.billingClient?.id
        );
      });
    }
    return orderedMissions;
  }, [filterClientIds, filterMissionsIds, missions, subMissions]);

  useEffect(() => {
    setFilterMissionsIds(missionId ? [missionId] : []);
  }, [missionId, setFilterMissionsIds]);

  if (dataActivity?.length === 0) {
    return (
      <TableRow>
        <TableCell
          sx={{
            border: '1px solid',
            borderColor: 'darkGrey.dark',
            background: 'darkGrey.main',
            textAlign: 'center',
          }}
          colSpan={8}
        >
          <Typography>
            {'Aucune mission régie active sur la période'}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }
  return (
    <TableBody>
      {_.map(filteredMissions, (mission: ActivityNode) => {
        const compressedAssignments = _(mission.assignments)
          .map((assignment) => {
            const overlappingAssignments = _.filter(
              mission.assignments,
              (a) => {
                return a.employee.id === assignment.employee.id;
              }
            );
            return getCompressedAssignmentWithMaxRange(overlappingAssignments);
          })
          .uniqBy('employee')
          .filter((assignment) =>
            filterCollabIds.length > 0
              ? _.includes(filterCollabIds, assignment.employee.id)
              : true
          )
          .value();

        if (!(mission.assignments && !_.isEmpty(compressedAssignments))) {
          return <></>;
        }

        if (
          _.some(compressedAssignments, (assignment) => {
            return (
              moment(assignment.expirationDate).isAfter(dateRange.start) &&
              moment(assignment.beginningDate).isBefore(dateRange.end)
            );
          })
        ) {
          const currentMissionActivityMonitorings = _.find(
            dataActivity,
            (activity) => {
              return activity.id === mission.id;
            }
          )?.activityMonitoring;
          return (
            <MissionRow
              key={`${mission.id}`}
              mission={mission}
              assignments={compressedAssignments}
              missionActivityMonitorings={currentMissionActivityMonitorings}
            />
          );
        }
      })}
    </TableBody>
  );
}
