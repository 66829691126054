import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';
import { Stack, Typography } from '@mui/material';
import React from 'react';

import { StaffingStatusEnum } from '../utils';

interface NoAssignmentsProps {
  activeFilter?: StaffingStatusEnum;
}

const getNoAssignmentsMessage = (
  activeFilter: StaffingStatusEnum | undefined
) => {
  switch (activeFilter) {
    case StaffingStatusEnum.CURRENT:
      return 'Aucun staffing actuel';
    case StaffingStatusEnum.HISTORY:
      return 'Aucun staffing passé';
    case StaffingStatusEnum.FUTURE:
      return 'Aucun staffing futur';
    default:
      return 'Aucun staffing';
  }
};

export default function NoAssignments({ activeFilter }: NoAssignmentsProps) {
  return (
    <Stack
      spacing={1}
      sx={{
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Stack
        component="span"
        sx={{
          border: '1px dashed',
          color: 'info.main',
          width: 54,
          height: 54,
          borderRadius: '50%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <WorkTwoToneIcon
          color="info"
          sx={{
            fontSize: '30px',
          }}
        />
      </Stack>
      <Typography
        variant="bodySemiBold"
        color={'text.secondary'}
        textAlign={'center'}
      >
        {getNoAssignmentsMessage(activeFilter)}
      </Typography>
    </Stack>
  );
}
