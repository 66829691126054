import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import ActivityHeader from 'components/AdminPage/AdminActivities/ActivityHeader';
import ActivityIndicators from 'components/AdminPage/AdminActivities/ActivityIndicators';
import ActivityStaffing from 'components/AdminPage/AdminActivities/ActivityStaffing';
import FinancialReport from 'components/AdminPage/AdminActivities/FinancialReport';
import MissionDetails from 'components/AdminPage/AdminActivities/MissionDetails';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import PageWrapper from 'components/commons/PageWrapper';
import PolyAlert from 'components/commons/PolyAlert';
import PolyAlertTitle from 'components/commons/PolyAlertTitle';
import { getUrlList } from 'components/Navigation/DesktopNavbar/utils';
import SubTabsNavigation from 'components/Navigation/SubTabsNavigation/SubTabsNavigation';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivitiesActivityTypeChoices,
  ActivityNode,
  useActivityInformationQuery,
  useAllEmployeesForActivitiesListLazyQuery,
  useLatestIndicatorQuery,
} from 'generated/graphql';
import { enqueueSnackbar } from 'notistack';
import { DATA_LOADING_ERROR_MESSAGE } from 'poly-constants';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { setEmployeesForCurrentActivity } from 'store/users';

const AdminActivityPage = () => {
  const FINANCIAL_REPORT_FLAG = useEnvironmentVariable(
    'admin_financial_report_tab'
  );
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { adminActivities } = getUrlList();
  const history = useHistory();
  const { data: latestIndicatorData } = useLatestIndicatorQuery({
    variables: { activityId: id },
  });
  const { data, loading, refetch } = useActivityInformationQuery({
    variables: {
      activityId: id,
    },
    onError: () => {
      enqueueSnackbar(DATA_LOADING_ERROR_MESSAGE, {
        variant: 'error',
      });
      history.push(adminActivities);
    },
  });
  const [getAllEmployees] = useAllEmployeesForActivitiesListLazyQuery({
    onCompleted: ({ allEmployeesForActivities }) => {
      if (allEmployeesForActivities) {
        dispatch(setEmployeesForCurrentActivity(allEmployeesForActivities));
      }
    },
  });

  const mission = data?.activityInformation as ActivityNode;
  const { employee, isSuperuser, isComex, isControl, isHR } = useUserInfo();

  const isMissionTypeHR = mission?.type === ActivitiesActivityTypeChoices.Hr;

  const isMissionChiefForActivity = mission?.chiefs?.some(
    (chief) => chief?.id === employee?.id
  );

  const isDirectorForActivity = mission?.director?.id === employee?.id;

  const isUserAllowedToEditMissionDetails = (mission: ActivityNode) => {
    return (
      isSuperuser ||
      isMissionChiefForActivity ||
      isComex ||
      isControl ||
      (mission && mission.type === ActivitiesActivityTypeChoices.Hr && isHR)
    );
  };

  const isUserAllowedToViewIndicators =
    isSuperuser ||
    isMissionChiefForActivity ||
    isDirectorForActivity ||
    isComex;

  const isUserAllowedToEditStaffing =
    isSuperuser || isMissionChiefForActivity || isComex;

  const isBillable = mission?.type === ActivitiesActivityTypeChoices.Ext;

  const shouldDisplayFinancialTab =
    FINANCIAL_REPORT_FLAG &&
    isBillable &&
    (isSuperuser || isMissionChiefForActivity || isComex || isControl);

  useEffect(() => {
    if (mission?.id) {
      getAllEmployees({
        variables: { activityIds: [mission?.id] },
      });
    }
  }, [mission?.id, getAllEmployees]);

  const generalInfoTab = {
    name: 'Informations générales'.toUpperCase(),
    to: 'general_info',
    icon: <></>,
    componentRendered: (
      <>
        <Stack flexDirection="row" gap={2}>
          {isBillable && isUserAllowedToViewIndicators && (
            <ActivityIndicators
              activity={mission}
              latestIndicator={latestIndicatorData?.latestIndicator}
            />
          )}
          <MissionDetails mission={mission} />
        </Stack>
        <ActivityStaffing
          isEditable={isUserAllowedToEditStaffing}
          activity={mission}
        />
      </>
    ),
  };

  const financialReportTab = {
    name: 'Suivi financier'.toUpperCase(),
    to: 'financial_report',
    icon: <></>,
    componentRendered: <FinancialReport activityId={id} />,
  };

  const tabs = shouldDisplayFinancialTab
    ? [generalInfoTab, financialReportTab]
    : [generalInfoTab];

  return (
    <PageWrapper noLimit>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            height: '800px',
            alignItems: 'center',
          }}
        >
          <LoadingPlaceholder />
        </Box>
      ) : (
        <>
          <ActivityHeader
            activity={mission}
            isEditable={isUserAllowedToEditMissionDetails(mission)}
            refetchActivityInfo={refetch}
          />
          {isMissionTypeHR ? (
            <PolyAlert severity="info" variant="outlined">
              <PolyAlertTitle color="info">Information</PolyAlertTitle>
              Vous avez sélectionné une mission &quot;Vie du cabinet&quot; qui
              ne possède pas d’informations de mission. Tous les collaborateurs
              peuvent déclarer du temps sur cette mission.
            </PolyAlert>
          ) : (
            <SubTabsNavigation
              variant="standard"
              titleSize="large"
              items={tabs}
            />
          )}
        </>
      )}
    </PageWrapper>
  );
};

export default AdminActivityPage;
