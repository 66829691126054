import PolyModal from 'components/MUIOverload/PolyDialog/PolyModal';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivityNode,
} from 'generated/graphql';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import urljoin from 'url-join';

import { ExternalActivityForm } from './ExternalActivityForm';

interface CreateExternalActivityModalProps {
  open: boolean;
  onClose: () => void;
  activityType: ActivitiesActivityBillingTypeChoices;
}

export const ExternalActivityModal = ({
  open,
  onClose,
  activityType,
}: CreateExternalActivityModalProps): JSX.Element => {
  const { path } = useRouteMatch();
  const history = useHistory();

  const activityTypeString =
    activityType === ActivitiesActivityBillingTypeChoices.Package
      ? 'forfait'
      : 'régie';

  const afterCreate = (activity?: ActivityNode) => {
    onClose();

    activity && history.push(urljoin(path, activity.id as string));
  };

  return (
    <PolyModal
      open={open}
      onClose={onClose}
      polyDialogTitle={`Création d'une nouvelle mission ${activityTypeString}`}
      maxWidth="md"
      noSecondaryButton
    >
      <ExternalActivityForm
        closeModal={(activity?: ActivityNode) => afterCreate(activity)}
        activityType={activityType}
      />
    </PolyModal>
  );
};
