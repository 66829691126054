import {
  Box,
  Divider,
  Stack,
  SwipeableDrawer,
  SxProps,
  Typography,
} from '@mui/material';
import AvatarNameAndOccupationStack from 'components/commons/AvatarNameAndOccupationStack/AvatarNameAndOccupationStack';
import FieldDisplay from 'components/commons/FieldDisplay/FieldDisplay';
import SectionTitle from 'components/commons/SectionTitle';
import ModalityEmailView from 'components/MissionFollowUp/BillDetail/BillDetail/BillView/MissionSummaryView/ModalitiesView/ModalityEmailView';
import ModalityPostalView from 'components/MissionFollowUp/BillDetail/BillDetail/BillView/MissionSummaryView/ModalitiesView/ModalityPostalView';
import ModalityUploadView from 'components/MissionFollowUp/BillDetail/BillDetail/BillView/MissionSummaryView/ModalitiesView/ModalityUploadView';
import { getDisplayedType } from 'components/MissionFollowUp/BillDetail/MissionInitialization/ModalitiesMissionForm/ModalityCard';
import {
  DisplayAddress,
  parseAddress,
} from 'components/MissionFollowUp/DisplayAddress';
import {
  ActivitiesActivityTypeChoices,
  ActivityNode,
  BillingBillingModalitiesBillingTypeChoices,
} from 'generated/graphql';
import _ from 'lodash';
import { EMPTY_FIELD } from 'poly-constants';
import React from 'react';

import { getDefaultText } from '.';

interface ActivityInfoDrawerProps {
  activity: ActivityNode;
  open: boolean;
  setOpenDrawer: (open: boolean) => void;
}

export default function ActivityInfoDrawer({
  activity,
  open,
  setOpenDrawer,
}: ActivityInfoDrawerProps) {
  const isBillable = activity.type === ActivitiesActivityTypeChoices.Ext;
  const principalModality = activity.billingInformation?.billingModalities?.[0];

  return (
    <SwipeableDrawer
      open={open}
      anchor="right"
      onOpen={() => setOpenDrawer(true)}
      onClose={() => setOpenDrawer(false)}
      PaperProps={{ sx: { width: '580px' } }}
    >
      <Box px={4} py={5}>
        <Stack direction="column" gap={1}>
          <Typography variant="h3Bold">Informations mission</Typography>
          <Typography color="primary" variant="h3Bold">
            {activity.name}
          </Typography>
        </Stack>
        <Divider
          sx={{
            my: 3,
            borderColor: 'disableGrey.main',
            borderWidth: '1px',
          }}
        />
        {isBillable && (
          <>
            <SectionTitle variant="h3Bold">
              Modalités de facturation
            </SectionTitle>
            <Stack justifyContent="space-between" spacing={1.5} mt={3} mb={5}>
              <DrawerField
                title="Mode d’envoi"
                value={getDefaultText(
                  getDisplayedType(principalModality?.billingType || '')
                )}
              />
              {principalModality?.billingType ===
                BillingBillingModalitiesBillingTypeChoices.Email && (
                <ModalityEmailView modality={principalModality} />
              )}
              {principalModality?.billingType ===
                BillingBillingModalitiesBillingTypeChoices.Postal && (
                <ModalityPostalView modality={principalModality} />
              )}
              {principalModality?.billingType ===
                BillingBillingModalitiesBillingTypeChoices.Upload && (
                <ModalityUploadView modality={principalModality} />
              )}
              <DrawerField
                title="Adresse de facturation"
                value={
                  activity.billingInformation?.billingClient?.mainAddress ? (
                    <DisplayAddress
                      addressInformations={parseAddress(
                        activity.billingInformation?.billingClient
                      )}
                      displayVertical={true}
                      typographyVariant="bodySemiBold"
                    />
                  ) : (
                    getDefaultText(undefined)
                  )
                }
              />
            </Stack>
          </>
        )}
        <SectionTitle variant="h3Bold">Informations mission</SectionTitle>
        <Stack justifyContent="space-between" spacing={1.5} mt={3}>
          <DrawerField
            title="Client métier"
            value={getDefaultText(activity.client)}
          />
          <DrawerField
            title="Client à facturer"
            value={getDefaultText(
              activity.billingInformation?.billingClient?.corporateName
            )}
          />
          {activity.pysId && (
            <DrawerField
              title="Code mission"
              value={activity.pysId.toString()}
            />
          )}
          <DrawerField
            title="Directeur de mission"
            sx={{ gap: 1 }}
            value={
              activity.director ? (
                <AvatarNameAndOccupationStack employee={activity.director} />
              ) : (
                'À définir'
              )
            }
          />
          <DrawerField
            title="Chef(s) de mission"
            sx={{ gap: 1 }}
            value={
              activity.chiefs && activity.chiefs.length > 0 ? (
                <Stack gap={1}>
                  {_.map(activity.chiefs, (chief) => (
                    <AvatarNameAndOccupationStack employee={chief} />
                  ))}
                </Stack>
              ) : (
                'À définir'
              )
            }
          />
          <DrawerField
            title="Identifiant du marché"
            value={activity.billingInformation?.refMarket || EMPTY_FIELD}
          />
          <DrawerField
            title="Description mission"
            value={activity.description || EMPTY_FIELD}
          />
          <DrawerField
            title="Stack technique"
            value={activity.techStack || EMPTY_FIELD}
          />
        </Stack>
      </Box>
    </SwipeableDrawer>
  );
}

const DrawerField = (props: {
  title: string;
  value: string | JSX.Element;
  sx?: SxProps;
}) => (
  <FieldDisplay
    flexDirection="column"
    titleVariant="bodyBold"
    valueVariant="bodySemiBold"
    noWrap={false}
    showColon={false}
    {...props}
  />
);
