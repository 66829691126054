import PolySwitch from 'components/Navigation/PolySwitch';
import AccessWrapper from 'components/User/AccessWrapper';
import { EmployeeOccupations } from 'components/User/UserProvider';
import { EmployeesEmployeeGradeChoices } from 'generated/graphql';
import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import urljoin from 'url-join';

import AdminActivitiesPage from './AdminActivity/AdminActivitiesPage';
import AdminActivityPage from './AdminActivity/AdminActivityPage';
import AdminEmployeePage from './AdminEmployee/AdminEmployeePage';
import AdminEmployeesPage from './AdminEmployee/AdminEmployeesPage';

const AdminPage = () => {
  const { path } = useRouteMatch();

  return (
    <PolySwitch>
      <AccessWrapper
        restrictMissionChief
        occupations={[EmployeeOccupations.DG, EmployeeOccupations.CONTROL]}
        grades={[EmployeesEmployeeGradeChoices.Comex]}
      >
        <Route
          exact
          path={urljoin(path, 'employees')}
          component={AdminEmployeesPage}
        />
        <Route
          exact
          path={urljoin(path, 'employees', ':id')}
          component={AdminEmployeePage}
        />
        <Route
          exact
          path={urljoin(path, 'activities')}
          component={AdminActivitiesPage}
        />
        <Route
          path={urljoin(path, 'activities', ':id')}
          component={AdminActivityPage}
        />
      </AccessWrapper>
    </PolySwitch>
  );
};

export default AdminPage;
