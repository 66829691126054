import { GridColDef, GridToolbar } from '@mui/x-data-grid-pro';
import MuixTable from 'components/commons/MuixTable';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import { useUserInfo } from 'components/User/UserProvider';
import { AssignmentNode } from 'generated/graphql';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { EMPTY_FIELD, POLY_DATE } from 'poly-constants';
import React from 'react';

import MissionTypeCell from '../MissionTypeCell';
import {
  ActivityTypeEnum,
  AssignmentStatusTextChoices,
  getActivityTypeName,
  getAssignmentStatusChip,
} from '../utils';

interface EmployeeStaffingTableProps {
  assignments: AssignmentNode[];
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAssignment: React.Dispatch<
    React.SetStateAction<AssignmentNode | undefined>
  >;
}

const EmployeeStaffingTable = ({
  assignments,
  setIsOpen,
  setAssignment,
}: EmployeeStaffingTableProps) => {
  const ADMIN_BACK_OFFICE_FLAG = useEnvironmentVariable('admin_back_office');
  const { employee: loggedInEmployee, isSuperuser, isComex } = useUserInfo();

  const columns: GridColDef[] = [
    {
      field: 'mission',
      headerName: 'Mission',
      flex: 2,
      minWidth: 200,
      valueGetter: (params) => params.row.activity.name || EMPTY_FIELD,
    },
    {
      field: 'client',
      headerName: 'Client',
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.activity.client || EMPTY_FIELD,
    },
    {
      field: 'missionType',
      headerName: 'Type de mission',
      flex: 1,
      minWidth: 150,
      type: 'singleSelect',
      valueOptions: Object.values(ActivityTypeEnum),
      valueGetter: (params) => {
        return params.row.activity
          ? getActivityTypeName(params.row.activity)
          : EMPTY_FIELD;
      },
      renderCell: (params) => (
        <MissionTypeCell activity={params.row.activity} />
      ),
    },
    {
      field: 'beginningDate',
      headerName: 'Début',
      type: 'date',
      flex: 1,
      minWidth: 130,
      valueGetter: (params) =>
        params.value ? new Date(params.value) : undefined,
      renderCell: (params) =>
        params.value ? moment(params.value).format(POLY_DATE) : EMPTY_FIELD,
    },
    {
      field: 'expirationDate',
      headerName: 'Fin',
      type: 'date',
      flex: 1,
      minWidth: 130,
      valueGetter: (params) =>
        params.value ? new Date(params.value) : undefined,
      renderCell: (params) =>
        params.value ? moment(params.value).format(POLY_DATE) : EMPTY_FIELD,
    },
    {
      field: 'staffingRate',
      headerName: 'Taux (%)',
      type: 'number',
      flex: 1,
      minWidth: 100,
      valueGetter: (params) =>
        params.row.staffingRate
          ? `${params.row.staffingRate}\u00A0%`
          : EMPTY_FIELD,
    },
    {
      field: 'status',
      headerName: 'Statut',
      type: 'singleSelect',
      valueOptions: Object.values(AssignmentStatusTextChoices),
      flex: 1,
      minWidth: 130,
      align: 'center',
      valueGetter: (params) => getAssignmentStatusChip(params.row).label,
      renderCell: (params) => getAssignmentStatusChip(params.row).jsx,
      sortComparator: (v1, v2) => {
        const order = Object.values(AssignmentStatusTextChoices);
        return order.indexOf(v1) - order.indexOf(v2);
      },
    },
  ];
  return (
    <MuixTable
      ignoreDiacritics
      containerWidth={'100%'}
      containerHeight={'100%'}
      rows={assignments}
      columns={columns}
      pagination={true}
      pageSizeOptions={[25, 50, 100]}
      onRowClick={(item) => {
        if (!ADMIN_BACK_OFFICE_FLAG) {
          return;
        }
        const assignment = item.row as AssignmentNode;
        if (assignment) {
          const isUserChiefForActivity = assignment.activity?.chiefs?.some(
            (chief) => chief?.id === loggedInEmployee?.id
          );
          const isUserAllowedToEditAssignment =
            isSuperuser || isComex || isUserChiefForActivity;
          if (isUserAllowedToEditAssignment) {
            setAssignment(assignment);
            setIsOpen(true);
          } else {
            enqueueSnackbar('Opération non autorisée', { variant: 'error' });
          }
        } else {
          enqueueSnackbar('Une erreur est survenue', { variant: 'error' });
        }
      }}
      initialState={{
        pinnedColumns: { left: ['mission'], right: ['status'] },
        sorting: {
          sortModel: [{ field: 'status', sort: 'asc' }],
        },
        pagination: {
          paginationModel: { pageSize: 25, page: 0 },
        },
      }}
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
      sx={{
        '& .MuiDataGrid-cell': {
          ':focus': {
            outline: 'none',
          },
        },
        '& .MuiDataGrid-columnHeader': {
          ':focus , :focus-within': {
            outline: 'none',
          },
        },
        '.MuiDataGrid-row:hover': {
          cursor: 'pointer',
          backgroundColor: 'primary.light',
        },
        '.MuiDataGrid-row.Mui-hovered': {
          backgroundColor: 'primary.light',
        },
        borderRadius: '8px',
      }}
      rowSelection={false}
    />
  );
};

export default EmployeeStaffingTable;
