import { Box, Typography, TypographyProps, useTheme } from '@mui/material';
import { BillingAddressNode, BillingClientNode } from 'generated/graphql';
import _ from 'lodash';
import React from 'react';

import styles from './styles/AddressFormat.module.scss';

export const getStringAddress = (
  billingInfo: BillingClientNode | undefined
) => {
  return billingInfo
    ? `${billingInfo.mainAddress?.address || ''} ${
        billingInfo.mainAddress?.zipCode
      } ${billingInfo.mainAddress?.city} ${billingInfo.mainAddress?.country}`
    : '';
};

export const getStringAddressFromAddress = (address: AddressType) => {
  return `${address.address.value || ''} ${address.zipCode.value} ${
    address.city.value
  } ${address.country.value}`;
};

export interface AddressType {
  address: {
    title: string;
    value: string;
  };
  zipCode: {
    title: string;
    value: string;
  };
  city: {
    title: string;
    value: string;
  };
  country: {
    title: string;
    value: string;
  };
}

export const parseAddress = (billingInfo?: BillingClientNode) => {
  return {
    address: {
      title: "Voie et Complément d'adresse",
      value: billingInfo?.mainAddress?.address || '',
    },
    zipCode: {
      title: 'Code postal',
      value: billingInfo?.mainAddress?.zipCode || '',
    },
    city: {
      title: 'Ville',
      value: billingInfo?.mainAddress?.city || '',
    },
    country: {
      title: 'Pays',
      value: billingInfo?.mainAddress?.country || '',
    },
  };
};

export const parsePurchaseOrderAddress = (billingInfo?: BillingAddressNode) => {
  return {
    address: {
      title: "Voie et Complément d'adresse",
      value: billingInfo?.address || '',
    },
    zipCode: {
      title: 'Code postal',
      value: billingInfo?.zipCode || '',
    },
    city: {
      title: 'Ville',
      value: billingInfo?.city || '',
    },
    country: {
      title: 'Pays',
      value: billingInfo?.country || '',
    },
  };
};

interface DisplayAddressProps {
  addressInformations: AddressType;
  displayTabTitle?: boolean;
  displayVertical?: boolean;
  typographyVariant?: TypographyProps['variant'];
}

export const DisplayAddress = (properties?: DisplayAddressProps) => {
  const theme = useTheme();
  let displayAddressOrder = ['address', 'zipCode', 'city', 'country'];

  if (properties?.displayVertical === true) {
    displayAddressOrder = ['address', 'city', 'country'];
    const city = properties.addressInformations.city.value;
    const zipCode = properties.addressInformations.zipCode.value;
    if (!city.includes(zipCode)) {
      properties.addressInformations.city.value = `${city}, ${zipCode}`;
    }
  }

  const DisplayAddressElement = (
    toDisplay: {
      title: string;
      value: string;
    },
    index: number
  ): JSX.Element => {
    return (
      <Typography
        key={index}
        sx={{
          color: theme.palette.text.primary,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          fontSize: properties?.typographyVariant ? 'inherit' : '13px',
        }}
        variant={properties?.typographyVariant}
      >
        {properties?.displayTabTitle === true && (
          <p className={styles.addressTitle}>{toDisplay.title}</p>
        )}
        <p className={styles.addressText}>{toDisplay.value}</p>
      </Typography>
    );
  };

  return !_.isEmpty(properties?.addressInformations) ? (
    <Box
      className={styles.addressContainer}
      sx={{ flexDirection: properties?.displayVertical ? 'column' : 'row' }}
    >
      {displayAddressOrder.map((key, index) =>
        DisplayAddressElement(
          _.get(properties?.addressInformations, key),
          index
        )
      )}
    </Box>
  ) : (
    <></>
  );
};
